import React from 'react';
import { Mail } from '@mui/icons-material';
import { useDrag } from 'react-dnd';

import styles from './AutoScheduleModalEmail.module.css';
import { Campaign } from '../../../types/campaign';

interface AutoScheduleModalEmailProps {
  email: Campaign,
}

export default function AutoScheduleModalEmail({ email }: AutoScheduleModalEmailProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'EMAIL',
    item: { id: email.id },
    collect: ((monitor) => ({ isDragging: monitor.isDragging() })),
  }));
  return (
    <p
      ref={drag}
      className={styles.mail}
      style={(isDragging) ? { opacity: 0.5 } : { opacity: 1 }}
    >
      <Mail className={styles.icon} />
      <span>
        {email.template}
      </span>
    </p>
  );
}
