import React from 'react';
import { Info } from '@mui/icons-material';

import Button from 'components/library/Button';
import layout from 'components/library/style/layout.module.css';
import styles from './TableTitle.module.css';

interface CampaignSummaryTableTitleProps {
  isSelectedAll?: boolean,
  onUserInfo?: () => void,
  onSelectAll?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function TableTitle({
  isSelectedAll, onUserInfo, onSelectAll,
}: CampaignSummaryTableTitleProps) {
  return (
    <div className={styles.container}>
      <div className={`${layout.container} ${styles.title}`}>
        <div className={styles.group}>
          {
            (onSelectAll)
            && (
              <input
                type="checkbox"
                className={styles.checkbox}
                onChange={onSelectAll}
                checked={isSelectedAll}
              />
            )
          }
          <p>
            User
          </p>
          {
            (onUserInfo)
            && (
              <Button
                variant="ghost"
                onClick={onUserInfo}
              >
                <Info className={styles.info} />
              </Button>
            )
          }
        </div>
        <p>Sujet</p>
        <p>Template</p>
        <p>Statut</p>
        <p>Date schedulée</p>
        <p>Segment</p>
        <p>Audience</p>
        <p>Validation</p>
        <p>Sengrid</p>
        <p>Raison</p>
        <div />
      </div>
    </div>
  );
}
