import React, { useState, useRef } from 'react';
import { CheckCircle, Save } from '@mui/icons-material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Campaign } from 'types/campaign';

import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import Modal from 'components/Modal';
import useAPI from 'hooks/useAPI';
import { Status } from 'services/store/states';
import { getTags, sendTestCampaignById } from 'services/network/api/campaign';
import getErrorMessage from 'utils/error';

import styles from './CampaignSummaryTableEdition.module.css';

interface CampaignSummaryTableEditionProps {
  email: Campaign,
  onClose: () => void,
  onEdit: (id: number, email: { subject: string, html: string }) => void,
  isPreview: boolean,
}

export default function CampaignSummaryTableEdition({
  email, onClose, onEdit, isPreview = false,
}: CampaignSummaryTableEditionProps) {
  const [subject, setSubject] = useState(email.subject);
  const [body, setBody] = useState(email.origin);
  const [testEmails, setTestEmails] = useState<string | undefined>();
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [error, setError] = useState<string | undefined>();

  const { status: tagStatus, data } = useAPI({
    request: (source) => getTags(source),
  });

  const editorRef = useRef(null);

  const includeTextToCurrentPos = (event: any, text: string, ref: any) => {
    ref.current.model.change((writer: any) => writer
      .insertText(text, ref.current.model.document.selection.getFirstPosition()));
  };

  const handleSubject = (e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value);
  const handleSubmit = () => onEdit(email.id, { subject, html: body });

  const handleTestEmails = (e: React.ChangeEvent<HTMLInputElement>) => setTestEmails(
    e.target.value,
  );
  const sendEmails = async () => {
    if (testEmails && testEmails !== '') {
      setStatus(Status.LOADING);
      try {
        await sendTestCampaignById(email.id, testEmails);
        setStatus(Status.SUCCESS);
      } catch (err) {
        setStatus(Status.FAIL);
        setError(getErrorMessage(err));
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <header className={styles.header}>
        {
          (isPreview)
            ? (
              <>
                <p>
                  <b>
                    Object :
                  </b>
                  { subject }
                </p>
                <p className={styles.sender}>
                  <b>
                    Sender :
                  </b>
                  { email.sender }
                </p>
              </>
            )
            : (
              <p>
                <b>
                  Template:
                </b>
                { email.template }
              </p>
            )
        }
      </header>
      <main className={styles.main}>
        {
          (isPreview)
            ? (
              <div
                className={styles.body}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )
            : (
              <>
                <Input label="Object" name="object">
                  <InputText
                    placeholder="Email object"
                    value={subject}
                    onChange={handleSubject}
                  />
                </Input>
                <div>
                  <p>Tags</p>
                  {
                    (tagStatus === Status.SUCCESS)
                      ? (
                        <div className={styles['tag-list']}>
                          {
                            (data as string[]).map((tag: string) => (
                              <button
                                key={tag}
                                type="button"
                                className={styles.tag}
                                onClick={(e) => includeTextToCurrentPos(e, `[%${tag}%] `, editorRef)}
                              >
                                {tag}
                              </button>
                            ))
                          }
                        </div>
                      )
                      : <p>Chargement...</p>
                  }
                </div>
                <Input label="Mail content in HTML Format" name="body">
                  <CKEditor
                    editor={ClassicEditor}
                    data={body}
                    onChange={(event: any, editor: any) => {
                      if (editor.getData() !== '') setBody(editor.getData());
                      else setBody('<p></p>');
                    }}
                    onReady={(editor: any) => {
                      editorRef.current = editor;
                    }}
                  />
                </Input>
              </>
            )
        }
      </main>
      <footer className={`${isPreview ? styles['preview-footer'] : styles.footer}`}>
        {
          (isPreview)
          && (
            <>
              <Input
                label="Email test"
                name="testEmails"
                state={status}
                className={styles.email}
              >
                <>
                  <InputText
                    placeholder=""
                    value={testEmails || ''}
                    onChange={handleTestEmails}
                  />
                  {
                    (status === Status.FAIL)
                    && (
                      <p className={`${styles.hint} ${styles.fail}`}>{error}</p>
                    )
                  }
                  {
                    (status === Status.SUCCESS)
                    && (
                      <p className={`${styles.hint} ${styles.success}`}>
                        <CheckCircle />
                        <span>Les emails ont été envoyés</span>
                      </p>
                    )
                  }
                </>
              </Input>
              <Button
                onClick={sendEmails}
                disabled={!testEmails || testEmails === '' || status === Status.LOADING}
                className={styles.button}
              >
                {
                  (status === Status.LOADING)
                    ? <p>Chargement...</p>
                    : <p>Test</p>
                }
              </Button>
            </>
          )
        }
        <Button
          variant="ghost"
          onClick={onClose}
        >
          Close
        </Button>
        {
          (!isPreview)
          && (
            <Button
              onClick={handleSubmit}
            >
              <Save className={styles.icon} />
              Save
            </Button>
          )
        }
      </footer>
    </Modal>
  );
}
