// @ts-nocheck

import { useEffect, useRef } from 'react';

export default function useOutsideClickAlert(ref, handler, variable = null) {
  const callbackRef = useRef();
  callbackRef.current = handler;

  useEffect(() => {
    function handleClickOutside(event) {
      if (callbackRef.current && !ref?.current?.contains(event.target)) {
        callbackRef.current(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [ref, variable]);
}
