import React from 'react';

import useAPI from 'hooks/useAPI';
import TableTitle from 'components/TableTitle';
import Loading from 'components/library/Loading';
import { getWaitingCampaigns } from 'services/network/api/campaign';
import { toCampaigns } from 'services/network/adaptor/campaign';
import { Campaign } from 'types/campaign';
import layout from 'components/library/style/layout.module.css';

import styles from './WaitingCampaign.module.css';

export default function WaitingCampaign() {
  const { status, data } = useAPI({
    request: (source) => getWaitingCampaigns(source),
    adaptor: (response) => toCampaigns(response),
  });

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <div className={layout.container}>
          <p className={styles.title}>
            Waiting send
          </p>
          <p className={styles.description}>
            Campagne ayant le status &quot;waiting_send&quot;
          </p>
        </div>
      </header>
      <main>
        <TableTitle />
        <Loading status={status}>
          <div>
            {
              data && data.map((campaign: Campaign) => (
                <div className={styles.row}>
                  <div className={`${layout.container} ${styles.grid}`}>
                    <p key={campaign.id}>
                      { campaign.name }
                    </p>
                    <p>
                      { campaign.subject }
                    </p>
                    <p>
                      { campaign.template }
                    </p>
                    <p>
                      { campaign.status }
                    </p>
                    <p>
                      { campaign.date }
                    </p>
                    <p>
                      { campaign.segment }
                    </p>
                    <p>
                      { campaign.audience }
                    </p>
                    <p>
                      { campaign.updated }
                    </p>
                    <p>
                      <a
                        href={`https://sendgrid.com/marketing_campaigns/ui/campaigns/${campaign.sendgrid}/stat`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        { campaign.sendgrid }
                      </a>
                    </p>
                    <p>
                      { campaign.reason }
                    </p>
                    <div />
                  </div>
                </div>
              ))
            }
          </div>
        </Loading>
      </main>
    </section>
  );
}
