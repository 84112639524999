import React, { useState } from 'react';
import { Search } from '@mui/icons-material';

import layout from 'components/library/style/layout.module.css';
import Input from 'components/library/Input';
import InputText from 'components/library/InputText';

import styles from '../Template.module.css';

interface TemplateGridProps {
  children: (query: string, type: string) => React.ReactNode,
}

export default function TemplateGrid({ children }: TemplateGridProps) {
  const [query, setQuery] = useState('');
  const [type, setType] = useState('all');

  const handleQuery = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  return (
    <>
      <div className={styles['grid-title-container']}>
        <div className={layout.container}>
          <button
            type="button"
            className={`${styles.type} ${type === 'all' ? styles.selected : ''}`}
            onClick={() => setType('all')}
          >
            Tous les templates
          </button>
          <button
            type="button"
            className={`${styles.type} ${type === 'actu' ? styles.selected : ''}`}
            onClick={() => setType('actu')}
          >
            Actu
          </button>
          <button
            type="button"
            className={`${styles.type} ${type === 'marie' ? styles.selected : ''}`}
            onClick={() => setType('marie')}
          >
            Marie
          </button>
          <button
            type="button"
            className={`${styles.type} ${type === 'sophie' ? styles.selected : ''}`}
            onClick={() => setType('sophie')}
          >
            Sophie
          </button>
        </div>
      </div>
      <div className={styles['grid-title-container']}>
        <div className={layout.container}>
          <Input
            label=""
            name="query"
            classNameContainer={styles.searchbar}
          >
            <InputText
              placeholder="Rechercher un template"
              value={query}
              onChange={handleQuery}
            >
              <Search />
            </InputText>
          </Input>
        </div>
      </div>
      <div className={styles['grid-title-container']}>
        <div className={`${layout.container} ${styles.grid}`}>
          <p>
            ID
          </p>
          <p>
            Nom
          </p>
          <p>
            Défaut
          </p>
          <p>
            Langue
          </p>
          <p>
            Sujet
          </p>
          <p>
            Type
          </p>
          <p>
            Dernière modification
          </p>
          <div />
        </div>
      </div>
      { children(query, type) }
    </>
  );
}
