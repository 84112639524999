import { CampaignEmail } from 'types/campaign';

const initialState: { emails: CampaignEmail[], selected: number } = { emails: [], selected: 0 };

export default function emailsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  { type, payload }: { type: string, payload?: any },
) {
  switch (type) {
    case 'DELETE':
      return {
        ...state,
        emails: state.emails.filter((email) => email.id !== payload),
      };
    case 'EDIT':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.id === payload.id)
            ? { ...email, subject: payload.subject, body: payload.html }
            : email
        )),
      };
    case 'SCHEDULE':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.id === payload.id)
            ? ({ ...email, date: payload.date, status: 'schedule' })
            : email
        )),
      };
    case 'SELECT': {
      const newEmails = state.emails.map((email) => (
        (email.id === payload.id)
          ? { ...email, selected: !email.selected }
          : email
      ));
      return {
        ...state,
        emails: newEmails,
        selected: newEmails.filter((email) => email.selected).length,
      };
    }
    case 'SELECT_ALL': {
      return {
        ...state,
        emails: state.emails.map((email) => ({ ...email, selected: true })),
        selected: state.emails.length,
      };
    }
    case 'SELECT_SELECTION': {
      return {
        ...state,
        emails: state.emails.map((email) => ({
          ...email,
          selected: (payload.includes(email.id)),
        })),
        selected: payload.length,
      };
    }
    case 'UNSELECT_ALL': {
      return {
        ...state,
        emails: state.emails.map((email) => ({ ...email, selected: false })),
        selected: 0,
      };
    }
    case 'UNSCHEDULED_SELECTION':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.selected)
            ? ({ ...email, date: 'N/A', status: 'draft' })
            : email
        )),
      };
    case 'DELETE_SELECTION':
      return {
        ...state,
        emails: state.emails.filter((email) => !email.selected),
      };
    case 'SCHEDULE_SELECTION':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.selected)
            ? ({ ...email, date: payload, status: 'schedule' })
            : email
        )),
      };
    case 'SEND':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.id === payload)
            ? ({ ...email, status: 'sending' })
            : email
        )),
      };
    case 'VALIDATE_SELECTION':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.selected)
            ? ({ ...email, validation: new Date().toLocaleString() })
            : email
        )),
      };
    case 'INVALIDATE_SELECTION':
      return {
        ...state,
        emails: state.emails.map((email) => (
          (email.selected)
            ? ({ ...email, validation: 'N/A' })
            : email
        )),
      };
    case 'SET_EMAILS':
      return {
        ...state,
        emails: payload,
      };
    default:
      return state;
  }
}
