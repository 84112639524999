import React from 'react';
import { Delete, Edit, Send } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { Campaign, CampaignEmail } from 'types/campaign';

import Button from 'components/library/Button';
import layout from 'components/library/style/layout.module.css';
import styles from './CampaignSummaryTableRows.module.css';

interface CampaignSummaryTableRowsProps {
  emails: CampaignEmail[],
  disabled: boolean,
  onDelete: (id: number) => void,
  onSend: (id: number) => void,
  onSelect: (id: number) => void,
  onEdit: (email: Campaign) => void,
  onPreview: (email: Campaign) => void,
}

export default function CampaignSummaryTableRows({
  emails, disabled, onEdit, onSend, onDelete, onPreview, onSelect,
}: CampaignSummaryTableRowsProps) {
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      onDelete(Number.parseInt(id, 10));
    }
  };

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      const email = emails.find((curr) => curr.id === Number.parseInt(id, 10));
      if (email) {
        onEdit(email);
      }
    }
  };

  const handlePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      const email = emails.find((curr) => curr.id === Number.parseInt(id, 10));
      if (email) {
        onPreview(email);
      }
    }
  };

  const handleSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      onSend(Number.parseInt(id, 10));
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = (e.target as HTMLInputElement);
    if (name) {
      onSelect(Number.parseInt(name, 10));
    }
  };

  return (
    <div className={styles.grid}>
      {
          emails.map((email) => (
            <div
              role="button"
              tabIndex={-1}
              key={email.id}
              className={styles.outsiderow}
              onClick={() => onSelect(email.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSelect(email.id);
                }
              }}
            >
              <div
                className={`${layout.container} ${styles.row}`}
              >
                <label className={styles.group} htmlFor={email.id.toString()}>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    checked={email.selected}
                    name={`${email.id}`}
                    id={email.id.toString()}
                    onChange={handleSelect}
                  />
                  {email.name}
                </label>
                <Tooltip title="Cliquer pour prévisualiser" arrow>
                  <div>
                    <Button
                      type="custom"
                      id={email.id}
                      onClick={handlePreview}
                      className={styles.link}
                    >
                      {email.subject}
                    </Button>
                  </div>
                </Tooltip>
                <p>
                  {email.template}
                </p>
                <p className={`${styles.pill} ${styles[email.status]}`}>
                  {email.status}
                </p>
                <p>
                  {new Date(email.date).toLocaleString()}
                </p>
                <p>
                  {email.segment}
                </p>
                <p>
                  {email.audience}
                </p>
                <p>
                  {email.validation}
                </p>
                <p>
                  {email.sendgrid}
                </p>
                <p>
                  {email.reason}
                </p>
                {
                  (disabled)
                    ? <div />
                    : (
                      <div className={styles.buttongroup}>
                        <Button
                          id={email.id}
                          type="custom"
                          variant="ghost"
                          className={styles.action}
                          onClick={handleEdit}
                          disabled={disabled}
                        >
                          <Edit />
                        </Button>
                        <Button
                          id={email.id}
                          type="custom"
                          variant="ghost"
                          className={styles.action}
                          onClick={handleDelete}
                          disabled={disabled}
                        >
                          <Delete />
                        </Button>
                        <Button
                          id={email.id}
                          type="custom"
                          variant="ghost"
                          className={styles.action}
                          onClick={handleSend}
                          disabled={disabled}
                        >
                          <Send />
                        </Button>
                      </div>
                    )
                }
              </div>
            </div>
          ))
        }
    </div>
  );
}
