import React from 'react';
import * as dateFns from 'date-fns';
import { Event as EventIcon, Mail } from '@mui/icons-material';

import useCalendar from 'hooks/useCalendar';
import { Campaign, Event } from 'types/campaign';
import { toHuman } from 'utils/converter';

import styles from './CampaignSummaryCalendarDays.module.css';
import CampaignSummaryCalendarTitle from '../CampaignSummaryCalendarTitle';

interface CampaignSummaryCalendarDaysProps {
  events: Event,
  emails: Campaign[],
  children: (date: Date, previous: () => void, next: () => void) => React.ReactNode,
}

export default function CampaignSummaryCalendarDays({
  events, emails, children,
}: CampaignSummaryCalendarDaysProps) {
  const {
    days, currentDate, previous, next,
  } = useCalendar();
  return (
    <>
      {
        children(currentDate, previous, next)
      }
      <div className={styles.grid}>
        <CampaignSummaryCalendarTitle />
        {
          days.map(({ date, timestamp, sameMonth }) => (
            <div className={`${styles.date} ${dateFns.isSameDay(new Date(), timestamp) ? styles.today : ''}`}>
              <p
                className={`${!sameMonth ? styles.past : ''} ${styles.day}`}
              >
                { date }
              </p>
              {
                Object.keys(events).filter((event) => {
                  const eventDate = (events[event as keyof Event]);
                  if (eventDate) {
                    return dateFns.isSameDay(new Date(eventDate), timestamp);
                  }
                  return false;
                }).map((event) => (
                  <p className={styles.event}>
                    <EventIcon />
                    <span>
                      {toHuman(event)}
                    </span>
                  </p>
                ))
              }
              {
                emails.filter((email) => {
                  const eventDate = email.date;
                  if (eventDate && eventDate !== '' && eventDate !== 'N/A') {
                    return dateFns.isSameDay(new Date(eventDate), timestamp);
                  }
                  return false;
                }).map((email) => (
                  <p className={styles.mail}>
                    <Mail />
                    <span>
                      {email.template}
                    </span>
                  </p>
                ))
              }
            </div>
          ))
        }
      </div>
    </>
  );
}
