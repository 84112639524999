import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAutoDeleteTime, getToasts } from 'services/store/features/toasts/selectors';
import { removeToast } from 'services/store/features/toasts/actions';
import { AppDispatch, RootState } from 'services/store/store';

import Toasts from './Toasts';

const mapStateToProps = (state: RootState) => ({
  list: getToasts(state),
  autoDeleteTime: getAutoDeleteTime(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  actions: bindActionCreators({ removeToast }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
