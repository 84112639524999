import React from 'react';

import { Campaign, Event } from 'types/campaign';

import CampaignSummaryCalendarDays from './CampaignSummaryCalendarDays';
import CampaignSummaryCalendarToolbar from './CampaignSummaryCalendarToolbar';

interface CampaignSummaryCalendarProps {
  events: Event,
  emails: Campaign[],
}

export default function CampaignSummaryCalendar({ events, emails }: CampaignSummaryCalendarProps) {
  return (
    <CampaignSummaryCalendarDays
      events={events}
      emails={emails}
    >
      {
          (date, previous, next) => (
            <CampaignSummaryCalendarToolbar
              date={date}
              previous={previous}
              next={next}
            />
          )
        }
    </CampaignSummaryCalendarDays>
  );
}
