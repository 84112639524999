import React from 'react';
import * as dateFns from 'date-fns';
import { Event as EventIcon, Mail } from '@mui/icons-material';
import { useDrop } from 'react-dnd';

import { toHuman } from 'utils/converter';
import { Campaign } from 'types/campaign';

import AutoScheduleModalEmail from '../AutoScheduleModalEmail';

import styles from './AutoScheduleModalDay.module.css';

interface AutoScheduleModalDayProps {
  date: string,
  timestamp: Date,
  events: string[],
  emailsScheduled: Campaign[],
  emailsToSchedule: Campaign[],
  moveEmail: (item: Campaign, date: Date) => void,
}

export default function AutoScheduleModalDay({
  date, timestamp, events, emailsScheduled, emailsToSchedule, moveEmail,
}: AutoScheduleModalDayProps) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'EMAIL',
    drop: (item) => {
      moveEmail(item as Campaign, timestamp);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [timestamp]);

  const canDrop = () => (new Date() < new Date(timestamp));

  return (
    <div
      className={`${styles.day} ${
        (isOver && canDrop()) ? styles.success : ''
      } ${(isOver && !canDrop()) ? styles.error : ''
      } ${dateFns.isSameDay(new Date(), timestamp) ? styles.today : ''}
      `}
      ref={drop}
    >
      <p className={styles.date}>
        { date }
      </p>
      {
        events.map((event) => (
          <p
            className={styles.event}
            key={event}
          >
            <EventIcon />
            <span>
              {toHuman(event)}
            </span>
          </p>
        ))
      }
      {
        emailsScheduled.map((email) => (
          <p
            className={styles.mail}
            key={email.id}
          >
            <Mail />
            <span>
              {email.template}
            </span>
          </p>
        ))
      }
      {
        emailsToSchedule.map((email) => (
          <AutoScheduleModalEmail email={email} key={email.id} />
        ))
      }
    </div>
  );
}
