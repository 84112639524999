import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Search } from '@mui/icons-material';

import layout from 'components/library/style/layout.module.css';
import styles from './Navigation.module.css';

export default function Navigation() {
  const [projectId, setProjectId] = useState('');
  const navigate = useNavigate();
  const handleProjectId = (e: React.ChangeEvent<HTMLInputElement>) => setProjectId(e.target.value);
  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      navigate(`/configuration/${projectId}`);
      setProjectId('');
    }
  };
  return (
    <nav className={styles.container}>
      <div className={`${layout.container} ${layout.row} ${styles.toolbar}`}>
        <div className={`${layout.row} ${styles.navigation}`}>
          <div>
            <p className={`${layout.row} ${styles.content}`}>
              <span className={styles.logo}>
                AYOMI
              </span>
              <span className={styles.point} />
              <span className={styles.soft}>
                Campaign
              </span>
            </p>
          </div>
          <NavLink
            to="/configuration"
            className={({ isActive }) => `${styles.link} ${isActive ? styles.active : styles.inactive}`}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/template"
            className={({ isActive }) => `${styles.link} ${isActive ? styles.active : styles.inactive}`}
          >
            Templates
          </NavLink>
          <NavLink
            to="/tasks"
            className={({ isActive }) => `${styles.link} ${isActive ? styles.active : styles.inactive}`}
          >
            Tasks
          </NavLink>
          <NavLink
            to="/waiting"
            className={({ isActive }) => `${styles.link} ${isActive ? styles.active : styles.inactive}`}
          >
            Waiting send
          </NavLink>
        </div>
        <div className={styles.searchbar}>
          <Search className={styles.icon} />
          <input
            type="text"
            placeholder="Taper un ID projet"
            value={projectId}
            onChange={handleProjectId}
            onKeyDown={handleSubmit}
          />
        </div>
      </div>
    </nav>
  );
}
