import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import { Status } from 'services/store/states';
import getErrorMessage, { isAxiosRetryError } from 'utils/error';
import { keysToCamel } from 'utils/converter';

interface UseAPIProps<T = Record<string, unknown> | unknown[]> {
  request?: (source: CancelTokenSource, payload?: unknown) => Promise<AxiosResponse>,
  multiRequest?: (source: CancelTokenSource, payload?: unknown) => Promise<AxiosResponse>[],
  adaptor?: (data: unknown) => T,
  cached?: string,
  wait?: boolean,
}

export default function useAPI<T>({
  request, multiRequest, adaptor, cached, wait = false,
}: UseAPIProps<T>) {
  const [status, setStatus] = useState(Status.IDLE);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T>();

  let call = 0;

  const handleRequest = async ({ source, payload }: {
    source?: CancelTokenSource, payload?: unknown,
  }) => {
    if (request) {
      let newSource = source;
      if (!newSource) {
        const cancelToken = axios.CancelToken;
        newSource = cancelToken.source();
      }
      setStatus(Status.LOADING);
      const cachedResult = cached ? window.sessionStorage.getItem(cached) : undefined;
      let response;
      try {
        if (cachedResult) {
          response = JSON.parse(cachedResult);
        } else {
          const { data: responseData } = await request(newSource, payload);
          response = responseData;
          if (cached) {
            window.sessionStorage.setItem(cached, JSON.stringify(response));
          }
        }
        setData((adaptor) ? adaptor(response) : (response));
        setStatus(Status.SUCCESS);
      } catch (err) {
        if (!axios.isCancel(err)) {
          const newError = getErrorMessage((err as AxiosError)?.response?.data || err);
          if (call > 0 && !isAxiosRetryError(newError)) {
            setError(newError);
            setStatus(Status.FAIL);
          }
        }
      } finally {
        call -= 1;
      }
    }
  };

  const handleMultiRequest = async ({ source, payload }: {
    source?: CancelTokenSource, payload?: unknown,
  }) => {
    if (multiRequest) {
      setStatus(Status.LOADING);
      let newSource = source;
      if (!newSource) {
        const cancelToken = axios.CancelToken;
        newSource = cancelToken.source();
      }
      try {
        const responses = await Promise.all(multiRequest(newSource, payload));
        const response = responses.map((newResponse) => newResponse.data);
        setData((adaptor) ? adaptor(response) : keysToCamel(response));
        setStatus(Status.SUCCESS);
      } catch (err) {
        if (!axios.isCancel(err)) {
          const newError = getErrorMessage((err as AxiosError)?.response?.data || err);
          if (call > 0 && !isAxiosRetryError(newError)) {
            setError(newError);
            setStatus(Status.FAIL);
          }
        }
      }
    }
  };

  const fetchData = async ({ source, payload }: {
    source?: CancelTokenSource, payload?: unknown,
  }) => {
    if (request) {
      await handleRequest({ source, payload });
    } else if (multiRequest) {
      await handleMultiRequest({ source, payload });
    }
  };

  const handleFetchData = ({ source, payload }: {
    source?: CancelTokenSource, payload?: unknown,
  }) => {
    call += 1;
    fetchData({ source, payload });
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    if (status === Status.IDLE && !wait) {
      handleFetchData({ source });
    }

    return () => source.cancel('axios request cancelled');
  }, []);

  return {
    status, data, error, setData, fetchData: handleFetchData,
  };
}
