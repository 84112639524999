import React, { useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Campaign, CampaignEmail, Project, Task,
} from 'types/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import {
  deleteCampaignById, invalidateCampaignById, unscheduledCampaignById,
  updateCampaignById, validateCampaignById,
} from 'services/network/api/campaign';
import getErrorMessage from 'utils/error';
import {
  getEmailsFromQuery, sendCampaign, updateAllCampaigns, createCampaign,
} from 'utils/campaign';
import TestEmailsModal from 'components/TestEmailsModal';
import ScheduleModal from 'components/ScheduleModal';
import AutoScheduleModal from 'components/AutoScheduleModal';
import Modal from 'components/Modal';
import Button from 'components/library/Button';

import CampaignSummaryHeader from './CampaignSummaryHeader';
import CampaignSummaryToolbar from './CampaignSummaryToolbar';
import CampaignSummaryTable from './CampaignSummaryTable';
import CampaignSummaryCalendar from './CampaignSummaryCalendar';
import emailsReducer from './emailsReducer';
import CampaignCreation from '../CampaignCreation';

import styles from './CampaignSummary.module.css';

interface CampaignSummaryProps {
  campaigns: {
    campaign: Campaign[],
    project: Project,
    task?: Task,
  },
  fetchCampaigns: () => void,
  setCampaigns: (data: {
    campaign: Campaign[], project: Project, task?: Task
  } | ((curr: {
    campaign: Campaign[], project: Project, task?: Task
  }) => { campaign: Campaign[], project: Project, task?: Task })) => void,
}

export default function CampaignSummary({
  campaigns, fetchCampaigns, setCampaigns,
}: CampaignSummaryProps) {
  const initialState: { emails: CampaignEmail[], selected: number } = {
    emails: campaigns.campaign
      .map((email) => ({ ...email, selected: false })) || [],
    selected: 0,
  };
  const [{ emails, selected }, dispatch] = useReducer(emailsReducer, initialState);
  const [isTestEmailOpen, setIsTestEmailOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isCreation, setIsCreation] = useState(false);
  const [isAutoScheduleOpen, setIsAutoScheduleOpen] = useState(false);
  const [{ showConfirmationModal, pendingAction }, setShowConfirmationModal] = useState<{
    showConfirmationModal: boolean, pendingAction?:() => void
  }>({
        showConfirmationModal: false, pendingAction: undefined,
      });
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);

  const globalDispatch = useDispatch();

  const { id: projectIdParams } = useParams();
  let projectId: number | undefined;

  if (projectIdParams) {
    projectId = Number.parseInt(projectIdParams, 10);
  }

  const closeConfirmationModal = () => setShowConfirmationModal({
    showConfirmationModal: false,
    pendingAction: undefined,
  });

  const handleConfirmationModal = (
    action?: () => void,
  ) => setShowConfirmationModal({
    showConfirmationModal: true,
    pendingAction: action,
  });

  const launchConfirmationModal = () => {
    if (pendingAction) pendingAction();
    closeConfirmationModal();
  };

  const handleDelete = (id: number) => {
    handleConfirmationModal(async () => {
      try {
        await deleteCampaignById(id);
        dispatch({ type: 'DELETE', payload: id });
      } catch (err) {
        globalDispatch(addToast({
          type: 'error',
          title: 'Impossible de supprimer la campagne',
          description: getErrorMessage(err),
        }) as any);
      }
    });
  };

  const handleSend = (id: number) => handleConfirmationModal(
    () => sendCampaign(
      id,
      globalDispatch,
      projectId,
      () => dispatch({ type: 'SEND', payload: id }),
    ),
  );

  const handleEdit = async (id: number, email: { subject: string, html: string }) => {
    try {
      await updateCampaignById(id, email);
      dispatch({ type: 'EDIT', payload: { id, ...email } });
    } catch (err) {
      globalDispatch(addToast({
        type: 'error',
        title: 'Impossible de mettre la campagne à jour',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const handleSelect = (id: number) => {
    dispatch({ type: 'SELECT', payload: { id } });
  };
  const handleSelectAll = (query?: string) => {
    if (!query) {
      dispatch({ type: 'SELECT_ALL' });
    } else {
      const selectedEmails = getEmailsFromQuery(query, emails).map((email) => email.id);
      dispatch({
        type: 'SELECT_SELECTION',
        payload: selectedEmails,
      });
    }
  };
  const handleUnselectAll = () => dispatch({ type: 'UNSELECT_ALL' });

  const handleTestEmails = () => setIsTestEmailOpen((state) => !state);

  const handleSchedule = () => setIsScheduleOpen((state) => !state);
  const handleScheduleSubmit = (date: string) => dispatch({ type: 'SCHEDULE_SELECTION', payload: date });
  const handleScheduleById = (date: string, emailId: number) => dispatch({ type: 'SCHEDULE', payload: { date, id: emailId } });

  const handleUnscheduled = async () => {
    try {
      const emailsToUnscheduled = (emails as CampaignEmail[])
        .filter((email: CampaignEmail) => email.selected)
        .map((email: CampaignEmail) => unscheduledCampaignById(email.id));
      await Promise.all(emailsToUnscheduled);
      dispatch({ type: 'UNSCHEDULED_SELECTION' });
      globalDispatch(addToast({
        type: 'success',
        title: 'Campagne unscheduled avec succès',
        description: '',
      }) as any);
    } catch (err) {
      globalDispatch(addToast({
        type: 'error',
        title: 'Impossible d\'unscheduled la/les campagne(s)',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const handleValidation = async () => {
    const campaignsToValidate = (emails as CampaignEmail[])
      .filter((email: CampaignEmail) => email.selected)
      .map((email: CampaignEmail) => validateCampaignById(email.id));
    try {
      await Promise.all(campaignsToValidate);
      dispatch({ type: 'VALIDATE_SELECTION' });
      globalDispatch(addToast({
        type: 'success',
        title: 'Campagne validée avec succès',
        description: '',
      }) as any);
    } catch (err) {
      globalDispatch(addToast({
        type: 'error',
        title: 'Impossible de valider la/les campagne(s)',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const handleInvalidate = async () => {
    const campaignsToInvalidate = (emails as CampaignEmail[])
      .filter((email: CampaignEmail) => email.selected)
      .map((email: CampaignEmail) => invalidateCampaignById(email.id));
    try {
      await Promise.all(campaignsToInvalidate);
      dispatch({ type: 'INVALIDATE_SELECTION' });
      globalDispatch(addToast({
        type: 'success',
        title: 'Campagne invalidée avec succès',
        description: '',
      }) as any);
    } catch (err) {
      globalDispatch(addToast({
        type: 'error',
        title: 'Impossible d\'invalidée la/les campagne(s)',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const handleBulkDelete = async () => {
    try {
      const emailsToDelete = (emails as CampaignEmail[])
        .filter((email: CampaignEmail) => email.selected)
        .map((email: CampaignEmail) => deleteCampaignById(email.id));
      await Promise.all(emailsToDelete);
      globalDispatch(addToast({
        type: 'success',
        title: 'Campagne supprimée avec succès !',
        description: '',
      }) as any);
      dispatch({ type: 'DELETE_SELECTION' });
    } catch (err) {
      globalDispatch(addToast({
        type: 'error',
        title: 'Impossible de valider la/les campagne(s)',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const handleUpdateAllCampaign = () => updateAllCampaigns(globalDispatch, projectId);

  const handleAutoSchedule = () => setIsAutoScheduleOpen((state) => !state);

  const handleAction = async (action: string) => {
    if (action === 'update-all') {
      await handleUpdateAllCampaign();
      return;
    }
    if (selected > 0) {
      if (action === 'email') handleTestEmails();
      if (action === 'schedule') handleSchedule();
      if (action === 'unscheduled') await handleUnscheduled();
      if (action === 'delete') {
        handleConfirmationModal(async () => {
          await handleBulkDelete();
        });
      }
      if (action === 'auto-schedule') handleAutoSchedule();
      if (action === 'validate') await handleValidation();
      if (action === 'invalidate') await handleInvalidate();
    } else {
      globalDispatch(addToast({
        type: 'warning',
        title: 'Veuillez sélectionnez la ou les campagne(s) avant de choisir votre action.',
        description: '',
      }) as any);
    }
  };

  const toggleCampaignCreation = () => {
    setIsCreatingCampaign(false);
    setIsCreation((state) => !state);
  };

  const handleCreation = async () => {
    await fetchCampaigns();
    toggleCampaignCreation();
  };

  const handleCampaignCreation = async (
    users: number[],
    templates: number[],
    segment: string,
    mailer: string,
  ) => {
    setIsCreatingCampaign(true);
    await createCampaign(
      {
        users, templates, segment, mailer,
      },
      globalDispatch,
      projectId,
      handleCreation,
    );
  };

  if (isCreation && !campaigns.task) {
    return (
      <CampaignCreation
        project={campaigns.project}
        users={campaigns.project.users || []}
        onPrevious={toggleCampaignCreation}
        onSubmit={handleCampaignCreation}
        isLoading={isCreatingCampaign}
      />
    );
  }

  return (
    <>
      {
        (showConfirmationModal)
        && (
          <Modal onClose={closeConfirmationModal}>
            <main className={styles['modal-confirmation']}>
              <p>Êtes-vous sûr ?</p>
            </main>
            <footer className={styles['modal-footer']}>
              <Button
                variant="ghost"
                onClick={closeConfirmationModal}
              >
                Annuler
              </Button>
              <Button onClick={launchConfirmationModal}>
                Confirmer
              </Button>
            </footer>
          </Modal>
        )
      }
      {
        (isTestEmailOpen)
        && (
          <TestEmailsModal
            onClose={handleTestEmails}
            selectedEmails={(emails as CampaignEmail[]).filter((email) => email.selected)}
          />
        )
      }
      {
        (isScheduleOpen)
        && (
          <ScheduleModal
            onClose={handleSchedule}
            selectedEmails={(emails as CampaignEmail[]).filter((email) => email.selected)}
            onSubmit={handleScheduleSubmit}
          />
        )
      }
      {
        (isAutoScheduleOpen)
        && (
          <AutoScheduleModal
            emails={emails}
            events={campaigns.project.event}
            selectedEmails={(emails as CampaignEmail[]).filter((email) => email.selected)}
            onSchedule={handleScheduleById}
            onClose={handleAutoSchedule}
          />
        )
      }
      <CampaignSummaryHeader
        task={campaigns.task}
        project={campaigns.project}
        setCampaigns={setCampaigns}
      />
      <CampaignSummaryToolbar
        openCreation={toggleCampaignCreation}
        onAction={handleAction}
        disabled={campaigns.task !== undefined}
      >
        {
          (isTable, query) => ((isTable)
            ? (
              <CampaignSummaryTable
                emails={getEmailsFromQuery(query, emails)}
                emailsSelected={selected}
                users={campaigns.project.users || []}
                disabled={campaigns.task !== undefined}
                onDelete={handleDelete}
                onEdit={handleEdit}
                onSend={handleSend}
                onSelect={handleSelect}
                onSelectAll={() => handleSelectAll(query)}
                onUnselectAll={handleUnselectAll}
              />
            )
            : (
              <CampaignSummaryCalendar
                events={campaigns.project.event}
                emails={getEmailsFromQuery(query, emails)}
              />
            ))
        }
      </CampaignSummaryToolbar>
    </>
  );
}
