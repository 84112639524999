import React from 'react';

import Modal from 'components/Modal';
import { SelectableTemplate } from 'types/campaign';
import styles from './TemplatePreview.module.css';

interface TemplatePreviewProps {
  template?: SelectableTemplate,
  onClose: () => void,
}

export default function TemplatePreview({ template, onClose }: TemplatePreviewProps) {
  return (
    <Modal onClose={onClose}>
      <header className={styles.header}>
        <p className={styles.name}>
          { template?.name }
        </p>
        <p className={styles.title}>
          { template?.subject }
        </p>
      </header>
      <main className={styles.main}>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: template?.content || '' }}
        />
      </main>
    </Modal>
  );
}
