import React from 'react';

import layout from 'components/library/style/layout.module.css';
import SegmentsInfo from 'components/SegmentsInfo';
import { Campaign, Project, Task } from 'types/campaign';

import styles from './CampaignSummaryHeader.module.css';
import CampaignConfigurationEdition from './CampaignConfigurationEdition';
import CampaignConfigurationSender from './CampaignConfigurationSender';

interface CampaignSummaryHeaderProps {
  task?: Task,
  project?: Project,
  setCampaigns: (data: {
    campaign: Campaign[], project: Project, task?: Task
  } | ((curr: {
    campaign: Campaign[], project: Project, task?: Task
  }) => { campaign: Campaign[], project: Project, task?: Task })) => void,
}

export default function CampaignSummaryHeader({
  project, task, setCampaigns,
}: CampaignSummaryHeaderProps) {
  return (
    <header className={`${layout.section} ${layout.container}`}>
      <div className={`${styles.header}`}>
        <div>
          <h1 className={styles.title}>
            {`Campagnes #${project?.id} : ${project?.name}`}
          </h1>
          <p className={styles.description}>
            Cette page a pour but de simplifier la gestion des envoies de campagne
          </p>
        </div>
      </div>
      {
        (task)
        && (
          <div className={styles.task}>
            <p className={styles['task-title']}>
              Une tâche est en cours d&apos;execution
              pour ce projet. Merci de revenir plus tard.
            </p>
            <p className={styles.description}>
              {`${task.name} - ${task.status}`}
            </p>
          </div>
        )
      }
      <div className={styles.row}>
        <p className={styles.contacts}>
          Information de configuration de la campagne
        </p>
        <CampaignConfigurationEdition
          data={{
            importantDate: project?.event,
            introduction: project?.introduction,
          }}
          setCampaigns={setCampaigns}
        />
      </div>
      <div className={styles.project}>
        <p>
          <b>
            Date de lancement au plus tôt / au plus tard :
          </b>
          { `${project?.event.startDate} / ${project?.event.lateStartDate}` }
        </p>
        <p>
          <b>
            1er évènement :
          </b>
          { project?.event.event1Date }
        </p>
        <p>
          <b>
            2ème évènement :
          </b>
          { project?.event.event2Date }
        </p>
        <p>
          <b>
            Date de closing :
          </b>
          { project?.event.closingDate }
        </p>
        <p>
          <b>
            Texte d&apos;explication de la société :
          </b>
          { project?.introduction.fr }
        </p>
        <p>
          <b>
            Texte d&apos;explication de la société - Anglais :
          </b>
          { project?.introduction.en }
        </p>
      </div>
      <p className={styles.contacts}>Information sur les contacts</p>
      <SegmentsInfo hideHeader />
      <CampaignConfigurationSender
        senders={project?.users}
      />
    </header>
  );
}
