import React from 'react';

import styles from './Input.module.css';

export default function Input({
  label, name, className, classNameContainer, state, children,
}: {
  label: string | React.ReactNode, name: string, className?: string, classNameContainer?: string,
  state?: string, children: React.ReactNode
}) {
  return (
    <div className={`${(state) ? styles[state.toLowerCase()] : ''} ${styles.container} ${classNameContainer || ''}`}>
      <label className={className} htmlFor={name}>
        { label }
      </label>
      { children }
    </div>
  );
}
