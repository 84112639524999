import getErrorMessage from './error';

export function fallbackCopyTextToClipboard(text: string, callback?: () => void) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  const successful = document.execCommand('copy');
  if (callback && successful) {
    callback();
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(
  text: string,
  onSuccess?: () => void,
  onError?: (error: string) => void,
) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, onSuccess);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {
    if (onSuccess) onSuccess();
  }, (err) => {
    if (onError) onError(getErrorMessage(err));
  });
}
