import React from 'react';
import { Replay } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import Loading from 'components/library/Loading';
import layout from 'components/library/style/layout.module.css';
import useAPI from 'hooks/useAPI';
import { createTask, getTasks } from 'services/network/api/campaign';
import { Task } from 'types/campaign';
import getErrorMessage from '../../utils/error';
import { addToast } from '../../services/store/features/toasts/actions';

import styles from './Tasks.module.css';
import { keysToCamel } from '../../utils/converter';

const isTasks = (value: unknown): value is Task[] => Array.isArray(value) && value.every((item) => (
  typeof item === 'object'
  && 'idCampaign' in item
  && 'name' in item
  && 'author' in item
  && 'log' in item
  && 'status' in item
  && 'timeStart' in item
  && 'timeEnd' in item
  && 'created' in item
  && 'action' in item
));

export default function Tasks() {
  const { status, data, fetchData } = useAPI<Task[]>({
    request: (source) => getTasks(source),
    adaptor: (value: unknown): Task[] => {
      const formattedValue = keysToCamel(value);
      if (isTasks(formattedValue)) {
        return formattedValue;
      }
      return [];
    },
  });

  const dispatch = useDispatch();

  const relaunchTask = async (task: Task) => {
    try {
      await createTask({ id_campaign: task.idCampaign, action: task.action });
      fetchData({});
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Echec de la relance de la tâche',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  return (
    <>
      <header className={layout.header}>
        <div className={layout.container}>
          <h1 className={layout.title}>
            Tasks
          </h1>
          <p className={layout.description}>
            Les tâches des actions qui tournent en arrière-plan
          </p>
        </div>
      </header>
      <main>
        <div className={styles['grid-title']}>
          <div className={`${layout.container} ${styles.grid}`}>
            <p>Created</p>
            <p>Name</p>
            <p>Author</p>
            <p>Log</p>
            <p>Status</p>
            <p>Time start</p>
            <p>Time end</p>
            <p />
          </div>
        </div>
        <Loading status={status}>
          <div>
            {
              (data && data.length > 0)
                ? (
                  data.map((task: Task) => (
                    <div className={styles.task} key={task.id}>
                      <div className={`${layout.container} ${styles.grid}`}>
                        <p>{task.created}</p>
                        <p>{task.name}</p>
                        <p>{task.author}</p>
                        <p className={styles[task.log]}>{task.log}</p>
                        <p className={styles[task.status]}>{task.status}</p>
                        <p>{task.timeStart}</p>
                        <p>{task.timeEnd}</p>
                        <div className={styles.actions}>
                          {
                            task.status === 'error'
                            && (
                              <button
                                type="button"
                                className={styles.button}
                                onClick={() => relaunchTask(task)}
                              >
                                <Replay />
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ))
                )
                : (<p>No tasks</p>)
            }
          </div>
        </Loading>
      </main>
    </>
  );
}
