import React from 'react';

import styles from './Loading.module.css';

export default function LoadingSpinner() {
  return (
    <div
      className={styles.loadingContainer}
      data-testid="load"
    >
      <div className={styles.loading}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
