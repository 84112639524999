import React from 'react';
import * as dateFns from 'date-fns';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';
import { ArrowLeft, ArrowRight, Cached } from '@mui/icons-material';

import useAPI from 'hooks/useAPI';
import useCalendar from 'hooks/useCalendar';
import { scheduleCampaignAutoByProjectId, scheduleCampaignById } from 'services/network/api/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import { Campaign, Event } from 'types/campaign';

import Modal from '../Modal';
import AutoScheduleModalDay from './AutoScheduleModalDay';
import styles from './AutoScheduleModal.module.css';
import Loading from '../library/Loading';
import Button from '../library/Button';
import AutoScheduleModalEmail from './AutoScheduleModalEmail';
import { keysToCamel } from '../../utils/converter';

interface AutoScheduleModalProps {
  events: Event,
  emails: Campaign[],
  selectedEmails: Campaign[],
  onSchedule: (date: string, id: number) => void,
  onClose: () => void,
}

interface AutoScheduleCampaign extends Campaign {
  requestDateSchedule: string,
}

interface AutoSchedule {
  log: string,
  result: AutoScheduleCampaign[],
  status: string,
}

const toAutoSchedule = (value: unknown): AutoSchedule => {
  const isAutoSchedule = (data: unknown): data is AutoSchedule => (!!data
    && typeof data === 'object'
    && 'log' in data
    && 'result' in data
    && 'status' in data);

  if (isAutoSchedule(value)) {
    return ({
      log: value.log,
      status: value.status,
      result: value.result.map((campaign: unknown) => {
        const newCampaign = keysToCamel(campaign);
        return ({
          ...newCampaign,
          template: newCampaign.template.name,
        });
      }),
    });
  }
  throw new Error('Invalid value');
};

export default function AutoScheduleModal({
  onSchedule, onClose, events, emails, selectedEmails,
}: AutoScheduleModalProps) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    status, data, setData, fetchData,
  } = useAPI<AutoSchedule>({
    request: (source) => scheduleCampaignAutoByProjectId(
      id ? Number.parseInt(id, 10) : 0,
      selectedEmails.map((email) => email.id),
      source,
    ),
    adaptor: toAutoSchedule,
  });

  const {
    currentDate, days, next, previous,
  } = useCalendar();

  const handleFetch = () => fetchData({});

  const moveEmail = (item: Campaign, date: Date) => {
    if (new Date() < new Date(date)) {
      const { id: emailId } = item;

      setData((state: AutoSchedule | undefined) => (
        state
          ? ({
            ...state,
            result: state.result.map((email) => ((email.id === emailId)
              ? ({ ...email, requestDateSchedule: date.toISOString() })
              : email)),
          })
          : ({
            log: '',
            result: [],
            status: '',
          })
      ));
    }
  };

  const submit = async () => {
    if (!data) return;
    try {
      await Promise.all(data.result.map((email: any) => {
        const date = new Date(email.requestDateSchedule);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ''}${date.getDate()} 00:00:00`;
        return scheduleCampaignById(
          email.id,
          formattedDate,
        );
      }));
      data.result.forEach((email: any) => {
        const date = new Date(email.requestDateSchedule);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ''}${date.getDate()} 00:00:00`;
        onSchedule(formattedDate, email.id);
      });
      dispatch(addToast({
        type: 'success',
        title: 'Les campagnes ont été mise à jour',
        description: '',
      }) as any);
      onClose();
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de schedule les campagnes',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const getEvents = (timestamp: Date) => Object.keys(events).filter((event) => {
    const eventDate = (events[event as keyof Event]);
    if (eventDate) {
      return dateFns.isSameDay(new Date(eventDate), timestamp);
    }
    return false;
  });

  const getScheduledEmail = (timestamp: Date) => emails.filter((email) => {
    if (
      email.status === 'draft'
      || email.status === 'sent'
      || email.status === 'waiting_send'
    ) {
      const eventDate = email.date;
      if (eventDate && eventDate !== '' && eventDate !== 'N/A') {
        return dateFns.isSameDay(new Date(eventDate), timestamp);
      }
    }
    return false;
  });

  const getEmailToSchedule = (timestamp: Date) => (data?.result
    ? data.result.filter((email: AutoScheduleCampaign) => {
      const eventDate = email.requestDateSchedule;
      if (eventDate && eventDate !== '' && eventDate !== 'N/A') {
        return dateFns.isSameDay(new Date(eventDate), timestamp);
      }
      return false;
    })
    : []
  );

  return (
    <Modal onClose={onClose}>
      <section className={styles.section}>
        <p className={styles.title}> Auto-schedule des campagnes </p>
        <div className={styles.header}>
          <Button
            className={styles.nav}
            onClick={previous}
          >
            <ArrowLeft className={styles.arrow} />
          </Button>
          <p className={styles.month}>
            {
              currentDate.toLocaleDateString(
                'fr-FR',
                { month: 'long', year: 'numeric' },
              )
            }
          </p>
          <Button
            onClick={next}
            className={styles.nav}
          >
            <ArrowRight className={styles.arrow} />
          </Button>
        </div>
        <Loading
          status={status}
        >
          <DndProvider backend={HTML5Backend}>
            <div className={styles.grid}>
              <p className={styles.days}>LUN</p>
              <p className={styles.days}>MAR</p>
              <p className={styles.days}>MER</p>
              <p className={styles.days}>JEU</p>
              <p className={styles.days}>VEN</p>
              <p className={styles.days}>SAM</p>
              <p className={styles.days}>DIM</p>
              {
                days.map(({ date, timestamp }) => (
                  <AutoScheduleModalDay
                    key={timestamp.toString()}
                    date={date}
                    timestamp={timestamp}
                    events={getEvents(timestamp)}
                    emailsScheduled={getScheduledEmail(timestamp)}
                    emailsToSchedule={getEmailToSchedule(timestamp)}
                    moveEmail={moveEmail}
                  />
                ))
              }
            </div>
            <div>
              <p> Liste des e-mails </p>
              <div className={styles['email-list']}>
                {
                  data?.result.map((email: Campaign) => (
                    <AutoScheduleModalEmail email={email} key={email.id} />
                  ))
                }
              </div>
            </div>
          </DndProvider>
        </Loading>
        <div className={styles['button-group']}>
          <Button
            variant="ghost"
            onClick={handleFetch}
          >
            <Cached className={styles.icon} />
            Actualiser
          </Button>
          <Button
            variant="outline"
            onClick={onClose}
          >
            Fermer
          </Button>
          <Button onClick={submit}>
            Accepter
          </Button>
        </div>
      </section>
    </Modal>
  );
}
