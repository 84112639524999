import React from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import Button from 'components/library/Button';
import layout from 'components/library/style/layout.module.css';

import styles from './CampaignSummaryCalendarToolbar.module.css';

interface CampaignSummaryCalendarToolbarProps {
  date: Date,
  previous: () => void,
  next: () => void,
}

export default function CampaignSummaryCalendarToolbar({
  date, previous, next,
}: CampaignSummaryCalendarToolbarProps) {
  return (
    <div className={styles.toolbar}>
      <div className={`${layout.container} ${styles.row}`}>
        <p className={styles.title}>
          { date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' }) }
        </p>
        <div className={styles.buttongroup}>
          <Button
            type="custom"
            className={styles.navigation}
            onClick={previous}
          >
            <ArrowLeft />
          </Button>
          <Button
            type="custom"
            className={styles.navigation}
            onClick={next}
          >
            <ArrowRight />
          </Button>
        </div>
      </div>
    </div>
  );
}
