import React from 'react';

import useAPI from 'hooks/useAPI';
import { getTemplates } from 'services/network/api/campaign';
import { toTemplates } from 'services/network/adaptor/campaign';
import { Status } from 'services/store/states';
import { SelectableTemplate, Templates } from 'types/campaign';

interface TemplateManagerProps {
  children: (
    status: Status,
    data: Templates | undefined,
    error: string | null,
    addTemplate: (template: SelectableTemplate) => void,
  ) => React.ReactNode,
}

export default function TemplateManager({ children }: TemplateManagerProps) {
  const {
    status, data, error, setData,
  } = useAPI({
    request: (cancel) => getTemplates(cancel),
    adaptor: toTemplates,
  });

  const addTemplate = (template: SelectableTemplate) => {
    if (template.id) {
      setData((prevData) => {
        if (prevData) {
          const { content, ...currData } = prevData;
          return {
            ...currData,
            content: content.map((curr) => ((curr.id === template.id)
              ? { ...template, updated: new Date().toLocaleString('fr') }
              : curr
            )),
          };
        }
        return undefined;
      });
    } else {
      setData((prevData) => {
        if (prevData) {
          const { content, ...currData } = prevData;
          return {
            ...currData,
            content: [
              ...content,
              { ...template, id: content.length + 2, updated: new Date().toLocaleString('fr') },
            ],
          };
        }
        return undefined;
      });
    }
  };

  return (
    <main>
      {
        children(status, data, error, addTemplate)
      }
    </main>
  );
}
