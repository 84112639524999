import * as types from './types';
import toastFactory, { Toast } from './factory';
import { AppDispatch } from '../../store';

export function addToast(properties: Toast) {
  return (dispatch: AppDispatch) => {
    const toast = toastFactory(properties);
    dispatch({ type: types.ADD_TOAST, payload: toast });
  };
}

export function removeToast(id: number) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: types.REMOVE_TOAST, payload: id });
  };
}
