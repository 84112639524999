import React, { useState } from 'react';
import { Save, Check } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import Button from 'components/library/Button';
import { Status } from 'services/store/states';
import { ProjectUser } from 'types/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import { updateSender } from 'services/network/api/campaign';

import styles from './CampaignConfigurationSenderEdit.module.css';

interface Props {
  sender: ProjectUser,
  setData: (data: ProjectUser[] | ((curr: ProjectUser[]) => ProjectUser[])) => void,
}

export default function CampaignConfigurationSenderEdit({ sender, setData }: Props) {
  const [status, setStatus] = useState(Status.IDLE);

  const dispatch = useDispatch();

  const saveSender = async () => {
    setStatus(Status.LOADING);
    try {
      await updateSender(sender.email, sender.nickname);
      setStatus(Status.SUCCESS);
      window.setTimeout(() => setStatus(Status.IDLE), 2000);
    } catch (err) {
      setStatus(Status.IDLE);
      dispatch(addToast({
        type: 'error',
        title: 'La mise à jour du sender a échoué',
        description: `${err}`,
      }) as any);
    }
  };

  return (
    <li className={styles.sender}>
      <label htmlFor="email">
        Email
        <input
          type="text"
          value={sender.email}
          disabled
        />
      </label>
      <label htmlFor="name">
        Nom
        <input
          type="text"
          value={sender.nickname}
          onChange={(e) => {
            setData((curr: ProjectUser[]) => curr.map((user) => {
              if (user.email === sender.email) {
                return {
                  ...user,
                  nickname: e.target.value,
                };
              }
              return user;
            }));
          }}
        />
      </label>
      {
        (status === Status.LOADING)
        && (
          <span className={styles['icon-container']}>
            <span className="spinner purple" />
          </span>
        )
      }
      {
        status === Status.SUCCESS
        && (
          <span className={styles['icon-container']}>
            <Check className={styles.icon} />
          </span>
        )
      }
      {
        (status === Status.IDLE)
        && (
          <Button
            variant="ghost"
            onClick={saveSender}
          >
            <Save />
          </Button>
        )
      }
    </li>
  );
}
