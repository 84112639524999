import React, { useState } from 'react';

import Button from 'components/library/Button';
import Loading from 'components/library/Loading';
import layout from 'components/library/style/layout.module.css';
import { SelectableTemplate, Template as TemplateType } from 'types/campaign';

import TemplateManager from './TemplateManager';
import TemplateGrid from './TemplateGrid';
import TemplateEdition from './TemplateEdition';
import styles from './Template.module.css';
import TemplatePreview from './TemplatePreview';
import TemplateGridRow from './TemplateGrid/TemplateGridRow';

export default function Template() {
  const [{ isEdit, templateId }, setIsEdit] = useState<{
    isEdit: boolean,
    templateId: number | null,
  }>({ isEdit: false, templateId: null });
  const [{ isPreview, previewId }, setShowPreview] = useState<{
    isPreview: boolean,
    previewId: number | null,
  }>({ isPreview: false, previewId: null });

  const openTemplateEdition = () => setIsEdit({ isEdit: true, templateId: null });
  const closeTemplateEdition = () => setIsEdit({ isEdit: false, templateId: null });
  const handleEdit = (id: number) => setIsEdit({ isEdit: true, templateId: id });
  const handlePreview = (id: number) => setShowPreview({ isPreview: true, previewId: id });
  const handleClosePreview = () => setShowPreview({ isPreview: false, previewId: null });

  return (
    <>
      <header className={layout.header}>
        <div className={`${layout.container} ${styles.row}`}>
          <div>
            <h1 className={layout.title}>
              Templates
            </h1>
            <p className={layout.description}>
              Gestion des templates emails
            </p>
          </div>
          {
            (isEdit)
              ? (
                <Button onClick={closeTemplateEdition}>
                  Voir les templates
                </Button>
              )
              : (
                <Button onClick={openTemplateEdition}>
                  Créer un nouveau template
                </Button>
              )
          }
        </div>
      </header>
      <TemplateManager>
        {
          (status, data, error, addTemplate) => (
            <Loading status={status} errorFallback={<p>{error}</p>}>
              <>
                {
                  (isPreview)
                  && (
                    <TemplatePreview
                      template={data?.content
                        .find((template: SelectableTemplate) => template.id === previewId)}
                      onClose={handleClosePreview}
                    />
                  )
                }
                {
                  (isEdit)
                    ? (
                      <TemplateEdition
                        template={data?.content
                          .find((template: SelectableTemplate) => template.id === templateId)}
                        onSubmit={addTemplate}
                      />
                    )
                    : (
                      <TemplateGrid>
                        {
                          (query: string, type: string) => (
                            data?.content
                              .filter((template: TemplateType) => {
                                if (type !== 'all') {
                                  return template.type === type;
                                }
                                return (type !== 'all'
                                  ? template.type === type
                                  : true) && (template.name.toLowerCase())
                                  .includes(query.toLowerCase());
                              })
                              .map((template: TemplateType) => (
                                <TemplateGridRow
                                  key={template.id}
                                  template={template}
                                  onEdit={handleEdit}
                                  onPreview={handlePreview}
                                />
                              ))
                          )
                        }
                      </TemplateGrid>
                    )
                }
              </>
            </Loading>
          )
        }
      </TemplateManager>
    </>
  );
}
