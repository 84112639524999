import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addToast } from 'services/store/features/toasts/actions';
import { scheduleCampaignById } from 'services/network/api/campaign';

import Modal from 'components/Modal';
import Input from 'components/library/Input';
import Button from 'components/library/Button';

import { CampaignEmail } from 'types/campaign';

import styles from '../SegmentsInfo/SegmentsInfo.module.css';

interface ScheduleModalProps {
  selectedEmails: CampaignEmail[],
  onClose: () => void,
  onSubmit: (date: string) => void,
}

export default function ScheduleModal({ onClose, selectedEmails, onSubmit }: ScheduleModalProps) {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const dispatch = useDispatch();

  const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => setDate(e.target.value);
  const handleTime = (e: React.ChangeEvent<HTMLInputElement>) => setTime(e.target.value);

  const handleSubmit = async () => {
    const formattedDate = `${date} ${time}:00`;
    try {
      await Promise.all(selectedEmails
        .map((email) => scheduleCampaignById(email.id, formattedDate)));
      dispatch(addToast({
        type: 'success',
        title: 'Campagne schedule avec succès',
        description: '',
      }) as any);
      onSubmit(formattedDate);
      onClose();
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de schedule la ou les campagnes',
        description: '',
      }) as any);
    }
  };

  return (
    <Modal onClose={onClose}>
      <header className={styles.header}>
        <p>
          Schedule campagne email
        </p>
      </header>
      <main className={styles.main}>
        <Input
          label="Date d'envoi souhaitée"
          name="date"
        >
          <input
            type="date"
            className={styles.input}
            value={date}
            onChange={handleDate}
          />
        </Input>
        <Input
          label="Heure d'envoi souhaitée"
          name="time"
        >
          <input
            type="time"
            className={styles.input}
            value={time}
            onChange={handleTime}
          />
        </Input>
      </main>
      <footer className={styles.footer}>
        <Button
          variant="ghost"
          onClick={onClose}
        >
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!time || !date || time === '' || date === ''}
        >
          Schedule
        </Button>
      </footer>
    </Modal>
  );
}
