import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Edit, Visibility } from '@mui/icons-material';

import layout from 'components/library/style/layout.module.css';
import { Status } from 'services/store/states';
import Button from 'components/library/Button';
import { updateTemplateById } from 'services/network/api/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import { Template } from 'types/campaign';
import getErrorMessage from 'utils/error';

import styles from '../../Template.module.css';

interface Props {
  template: Template,
  onEdit: (id: number) => void,
  onPreview: (id: number) => void,
}

export default function TemplateGridRow({ template, onEdit, onPreview }: Props) {
  const [status, setStatus] = useState(Status.IDLE);
  const [isDefault, setIsDefault] = React.useState(template.isDefault || false);
  const dispatch = useDispatch();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      onPreview(Number.parseInt(id, 10));
    }
  };
  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = (e.currentTarget as HTMLButtonElement).dataset;
    if (id) {
      onEdit(Number.parseInt(id, 10));
    }
  };

  const updateDefault = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!template.id) return;
    setStatus(Status.LOADING);
    try {
      const { checked } = e.target;
      await updateTemplateById(template.id.toString(), { is_default: checked });
      setIsDefault(checked);
      setStatus(Status.IDLE);
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Une erreur est survenue lors de la mise à jour du template',
        description: getErrorMessage(err),
      }) as any);
      setStatus(Status.IDLE);
    }
  };

  return (
    <div
      key={template.id}
      className={`fadeIn ${styles['grid-row']}`}
    >
      <div className={`${layout.container} ${styles['grid-content']}`}>
        <p>
          { template.id }
        </p>
        <p>
          { template.name }
        </p>
        <div className={styles['checkbox-container']}>
          <input
            type="checkbox"
            checked={isDefault}
            onChange={updateDefault}
            disabled={status === Status.LOADING}
          />
        </div>
        <p>
          { template.language }
        </p>
        <p>
          { template.subject }
        </p>
        <p>
          { template.type }
        </p>
        <p>
          { template.updated }
        </p>
        <div className={styles['button-group']}>
          <Button
            id={template.id}
            variant="ghost"
            onClick={handleOpen}
          >
            <Visibility />
          </Button>
          <Button
            id={template.id}
            variant="ghost"
            onClick={handleEdit}
          >
            <Edit />
          </Button>
        </div>
      </div>
    </div>
  );
}
