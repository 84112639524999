import React, { useState } from 'react';
import {
  Tooltip, styled, tooltipClasses, TooltipProps,
} from '@mui/material';

import { copyTextToClipboard } from 'utils/copyToClipboard';
import { Status } from 'services/store/states';

import styles from './CopyText.module.css';

interface CopyTextProps {
  label: string,
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#535353',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#535353',
    color: '#ffffff',
    fontSize: 11,
    fontStyle: 'italic',
    padding: '8px',
  },
}));

export default function CopyText({ label }:CopyTextProps) {
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const copyInClipboard = () => {
    copyTextToClipboard(label, () => {
      setStatus(Status.SUCCESS);
      window.setTimeout(() => setStatus(Status.IDLE), 1000);
    });
  };

  return (
    <StyledTooltip
      title={(status === Status.SUCCESS)
        ? 'Copié !'
        : 'Cliquer pour copier dans votre presse papier'}
      arrow
    >
      <button
        type="button"
        className={styles.copy}
        onClick={copyInClipboard}
      >
        { label }
      </button>
    </StyledTooltip>
  );
}
