import { CancelTokenSource } from 'axios';

import axios from 'services/network/axiosClient';
import { CampaignCreationBackend, TemplateBackend } from 'types/campaign';
import { keysToSnake } from 'utils/converter';

export const getCampaignsByProjectId = (id: string, source?: CancelTokenSource) => axios.get('/campaign', { params: { project_id: id }, cancelToken: source?.token });
export const getWaitingCampaigns = (source?: CancelTokenSource) => axios.get('/campaign', { params: { status: 'waiting_send' }, cancelToken: source?.token });

export const getProjectData = (id: string, source?: CancelTokenSource) => axios.get(`/project/project/${id}`, { cancelToken: source?.token });
export const getProjectPageData = (id: string, source?: CancelTokenSource) => axios.get(`/project/project_page/${id}`, { cancelToken: source?.token });
export const updateProjectData = (id: string, data: any) => axios.put(`/project/project_page/${id}`, keysToSnake(data));
export const updateProjectImportantDate = (id: string, data: any) => axios.put(`/project/important_date/${id}`, keysToSnake(data));

export const getContactData = (id: string, source?: CancelTokenSource) => axios.get(`/contact-data/${id}`, { cancelToken: source?.token });

export const getSenders = (id: string, source?: CancelTokenSource) => axios.get('/sender-sendgrid', { params: { project_id: id }, cancelToken: source?.token });
export const updateSender = (email: string, name: string) => axios.post('/sender-sendgrid', { email, name });

export const createCampaigns = (data: CampaignCreationBackend) => axios.post('/campaign', data);
export const deleteCampaignById = (id: number) => axios.delete(`/campaign/${id}`);
export const updateCampaignById = (id: number, email: { subject: string, html: string }) => axios.put(`/campaign/${id}`, email);
export const sendTestCampaignById = (id: number, emails: string) => axios.post(`/campaign/test/${id}`, { email_to: emails });
export const sendCampaignById = (id: number, project: number) => axios.post(`/campaign/send/${id}`, { project_id: project, campaign_id: id });
export const unscheduledCampaignById = (id: number) => axios.put(`/date-campaign/${id}`, { date_schedule: null });
export const scheduleCampaignById = (id: number, date: string) => axios.put(`/date-campaign/${id}`, { date_schedule: date });
export const updateAllCampaignsByProjectId = (projectId: number) => axios.post(`/campaign/update-all/${projectId}`);

export const scheduleCampaignAutoByProjectId = (
  projectId: number,
  campaigns: number[],
  source: CancelTokenSource,
) => axios.post(
  `/campaign/request-date-schedule/${projectId}`,
  { project_id: projectId, id_list: campaigns },
  { cancelToken: source?.token },
);

export const getTemplates = (source?: CancelTokenSource) => axios.get('/template', { cancelToken: source?.token });
export const createTemplate = (data: TemplateBackend) => axios.post('/template', data);
export const updateTemplateById = (id: string, data: Partial<TemplateBackend>) => axios.put(`/template/${id}`, keysToSnake(data));

export const getTasks = (source?: CancelTokenSource) => axios.get('/task', { cancelToken: source?.token });
export const createTask = (data: { id_campaign: string | number, action: string }) => axios.post('/task', data);

export const validateCampaignById = (id: number) => axios.put(`/validation/${id}`, { validation: true });
export const invalidateCampaignById = (id: number) => axios.put(`/validation/${id}`, { validation: false });

export const getTags = (source?: CancelTokenSource) => axios.get('/tag', { cancelToken: source?.token });
