export const toCamel = (s: string) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export const toSnake = (s: string) => {
  if (s === s.toUpperCase()) return s;
  return s.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
};

export const toHuman = (s: string) => {
  if (!s) return '';
  if (s === s.toUpperCase()) return s;
  return s.replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`).replaceAll('_', ' ');
};

const isArray = (a: unknown) => Array.isArray(a);

const isObject = (o: unknown) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    const n = o;

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k) as keyof typeof n] = keysToCamel(o[k]);
      });

    Object.keys(o)
      .forEach((k) => {
        if (k !== toCamel(k)) {
          delete n[k];
        }
      });

    return n;
  } if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i));
  }

  return o;
};

export const keysToSnake = (o: any) => {
  if (isObject(o)) {
    const n = o;

    Object.keys(o)
      .forEach((k) => {
        n[toSnake(k) as keyof typeof n] = keysToSnake(o[k]);
      });

    Object.keys(o)
      .forEach((k) => {
        if (k !== toSnake(k)) {
          delete n[k];
        }
      });

    return n;
  } if (isArray(o)) {
    return o.map((i: any) => keysToSnake(i));
  }

  return o;
};

export const capitalize = (str: string) => `${str[0].toUpperCase()}${str.slice(1)}`;
