import React from 'react';
import { useParams } from 'react-router-dom';

import useAPI from 'hooks/useAPI';
import { getContactData } from 'services/network/api/campaign';
import { toContact } from 'services/network/adaptor/campaign';
import { Contact } from 'types/campaign';

import styles from './SegmentsInfo.module.css';
import Loading from '../library/Loading';

interface SegmentsInfoProps {
  hideHeader?: boolean,
}

export default function SegmentsInfo({ hideHeader = false }: SegmentsInfoProps) {
  const { id } = useParams();

  const { status, data: segments } = useAPI({
    request: (source) => getContactData(id || '0', source),
    adaptor: (data) => toContact(data),
  });

  return (
    <Loading status={status}>
      <>
        <header
          className={styles.header}
          hidden={hideHeader}
        >
          <p>
            Information sur les contacts
          </p>
        </header>
        <main
          className={`${hideHeader ? styles.container : styles.main}`}
        >
          <div className={styles.grid}>
            <p
              className={styles.bold}
            >
              Segments
            </p>
            <p
              className={styles.bold}
            >
              All
            </p>
            <p
              className={styles.bold}
            >
              Blacklist
            </p>
            <p
              className={styles.bold}
            >
              Segment 1
            </p>
            <p
              className={styles.bold}
            >
              Segment 2
            </p>
            <p
              className={styles.bold}
            >
              Segment 3
            </p>
            <p
              className={styles.bold}
            >
              Segment 4
            </p>
            <p
              className={styles.bold}
            >
              Segment 5
            </p>
            <p
              className={styles.bold}
            >
              Segment 6
            </p>
            <p
              className={styles.bold}
            >
              Segment 7
            </p>
            <p
              className={styles.bold}
            >
              Segment 8
            </p>
            <p
              className={styles.bold}
            >
              Segment 9
            </p>
            <p
              className={styles.bold}
            >
              Segment 10
            </p>
            {
              (segments as Contact)?.users?.map((user) => (
                <React.Fragment key={user.username}>
                  <p>
                    {`${user.username} (${user.role})`}
                  </p>
                  {
                    ['all', 'blacklist', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((key) => (
                      <p key={`${user.username}-${key}`} className={styles.result}>
                        {`${user.segments[key as keyof typeof user.segments] || 0} / ${user.segmentsProd[key as keyof typeof user.segmentsProd] || 0}`}
                      </p>
                    ))
                  }
                </React.Fragment>
              ))
            }
            <p>Total</p>
            {
              ['all', 'blacklist', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((key) => (
                <p key={`total-${key}`} className={styles.result}>
                  {`${(segments?.segments && segments?.segments[key as keyof typeof segments.segments]) || 0} (${((((segments?.segments && segments?.segments[key as keyof typeof segments.segments]) || 0) * 100) / ((segments?.segments && segments?.segments.all) || 1)).toFixed(2)}%) / ${(segments?.segmentsProd && segments?.segmentsProd[key as keyof typeof segments.segmentsProd]) || 0}`}
                </p>
              ))
            }
          </div>
          <p className={styles.container}>
            <span className={styles.legend}>
              Légende :
            </span>
            Nouvelle segmentation / Segmentation prod
          </p>
        </main>
      </>
    </Loading>
  );
}
