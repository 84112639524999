import {
  BackCampaign, BackProject, BackTemplate,
  Campaign,
  CampaignCreationBackend,
  Contact,
  EditableTemplate,
  Project,
  Segment,
  SelectableTemplate,
  TemplateBackend,
  Templates,
} from 'types/campaign';

const isCampaign = (data: unknown): data is BackCampaign => !!data
  && typeof data === 'object'
  && 'id' in data
  && 'project_id' in data
  && 'template' in data;

const isBackCampaigns = (data: unknown): data is BackCampaign[] => !!data && Array.isArray(data)
  && data.every(isCampaign);

export const toCampaigns = (data: unknown): Campaign[] => {
  if (isBackCampaigns(data)) {
    return data.map((campaign: BackCampaign) => ({
      id: campaign.id,
      name: campaign.sender_name || 'N/A',
      subject: campaign.subject || 'N/A',
      body: campaign.html || 'N/A',
      origin: campaign.html_origin || 'N/A',
      template: campaign.template.name || 'N/A',
      status: campaign.status || 'Draft',
      date: campaign.date_campaign?.date_schedule,
      segment: campaign.audience.join(', ') || 'N/A',
      updated: campaign.updated,
      sendgrid: campaign.mailer_data?.campaign_id || null,
      sender: `${campaign.sender_name} - ${campaign.sender_email}`,
      reason: '', // Not in V1
      audience: campaign.number_audience_creation,
      validation: campaign.validation.validation ? campaign.validation.updated : 'N/A',
    }));
  }
  return [];
};

const isBackProject = (data: unknown): data is BackProject => !!data && typeof data === 'object'
  && 'id' in data
  && 'name' in data
  && 'senders' in data
  && 'important_date' in data;

export const toProject = (data: unknown): Project => {
  if (isBackProject(data)) {
    return ({
      event: {
        closingDate: data.important_date.expected_end_date,
        event1Date: data.important_date.event1_date,
        event2Date: data.important_date.event2_date,
        startDate: data.important_date.early_start_date || 'N/A',
        lateStartDate: data.important_date.late_start_date || 'N/A',
        earlyStartDate: data.important_date.early_start_date || 'N/A',
        nextStartDate: data.important_date.next_start_date || 'N/A',
        dryRunDate: data.important_date.dry_run_date || 'N/A',
        expectedEndDate: data.important_date.expected_end_date || 'N/A',
        realEndDate: data.important_date.real_end_date || 'N/A',
        realStartDate: data.important_date.real_start_date || 'N/A',
      },
      id: data.id,
      name: data.name,
      users: (data.senders || []).map(({ data_ayomi: user, data_sendgrid: sender }) => ({
        username: user.full_name || 'N/A',
        role: user.role || 'N/A',
        id: user.iss,
        email: user.email || 'N/A',
        isActive: user.status,
        nickname: (Array.isArray(sender) ? sender[0]?.nickname : sender?.nickname) || 'N/A',
      })),
      introduction: data.introduction || { fr: 'N/A', en: 'N/A' },
    });
  }
  throw new Error('Project Invalid Data');
};

const getSegments = (data: Issuer[], segmentKey: string): Segment => {
  const emptySegments = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  };

  let newSegments = {
    ...emptySegments,
  };

  data.forEach((issuer) => {
    if (issuer[segmentKey as keyof typeof issuer]) {
      newSegments = {
        ...newSegments,
        ...Object.entries(newSegments).reduce((acc, [key, value]) => {
          const issuerSegmentation = issuer[segmentKey as keyof typeof issuer];
          return ({
            [key]: value + (
              (issuerSegmentation
              && typeof issuerSegmentation === 'object'
              && (issuerSegmentation[key as unknown as keyof typeof issuerSegmentation]))
              || 0
            ),
          });
        }, {}),
      };
    }
  });

  return {
    ...newSegments,
    blacklist: (newSegments['9'] || 0) as number,
    all: Object.values(newSegments).reduce((acc: number, curr: number) => acc + curr, 0) as number,
  };
};

export interface Issuer {
  email: string,
  role: string,
  name: string,
  segment_info_prod?: {
    1: number,
    2: number,
    3: number,
    4: number,
    5: number,
    6: number,
    7: number,
    8?: number,
    9?: number,
    10?: number,
  },
  segment_info_theo?: {
    1: number,
    2: number,
    3: number,
    4: number,
    5: number,
    6: number,
    7: number,
    8?: number,
    9?: number,
    10?: number,
  },
}

const isIssuer = (value: unknown): value is Issuer[] => !!value
  && Array.isArray(value)
  && value.every((issuer: unknown): issuer is Issuer => (
    !!issuer
    && typeof issuer === 'object'
    && 'email' in issuer
    && 'role' in issuer
    && 'name' in issuer
  ));

export const toContact = (data: unknown): Contact => {
  if (isIssuer(data)) {
    const emptySegments: Segment = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      blacklist: 0,
      all: 0,
    };
    delete emptySegments['0' as keyof typeof emptySegments];
    const segmentsProd: Segment = getSegments(data, 'segment_info_prod');
    const segments: Segment = getSegments(data, 'segment_info_theo');
    return {
      segmentsProd: {
        ...segmentsProd,
        all: Object.values(segmentsProd).reduce((acc: number, curr: number) => acc + curr, 0),
        blacklist: segmentsProd['9'] || 0,
      },
      segments: {
        ...segments,
        all: Object.values(segments).reduce((acc: number, curr: number) => acc + curr, 0),
        blacklist: segments['7'] || 0,
      },
      users: data.map((user) => ({
        email: user.email || 'N/A',
        username: user.name || 'N/A',
        role: user.role || 'N/A',
        segmentsProd: (user.segment_info_prod ? {
          ...user.segment_info_prod,
          all: (Object.values(user.segment_info_prod) as number[])
            .reduce((acc: number, curr: number) => acc + (curr || 0), 0),
          blacklist: user.segment_info_prod['9'] || 0,
        } : emptySegments),
        segments: (user.segment_info_theo ? {
          ...user.segment_info_theo,
          all: (Object.values(user.segment_info_theo) as number[])
            .reduce((acc: number, curr: number) => acc + (curr || 0), 0),
          blacklist: user.segment_info_theo['7'] || 0,
        } : emptySegments),
      })),
    };
  }
  throw new Error('Issuer Invalid Data');
};

const isBackTemplate = (value: unknown): value is BackTemplate => !!value
  && typeof value === 'object'
  && 'contenu' in value
  && 'contenu_numero' in value
  && 'contenu_type' in value
  && 'id' in value
  && 'langue' in value
  && 'name' in value
  && 'subject' in value;

export const toSelectableTemplate = (data: unknown): SelectableTemplate => {
  if (isBackTemplate(data)) {
    return ({
      name: data.name,
      type: data.contenu_type,
      content: data.contenu,
      contentNumber: `${data.contenu_numero}`,
      language: data.langue,
      subject: data.subject,
      updated: data.updated,
      id: data.id,
      isSelected: data.is_default,
      isDefault: data.is_default,
    });
  }
  throw new Error('Template Invalid Data');
};

export const toEditableTemplate = (data?: SelectableTemplate): EditableTemplate | undefined => {
  if (!data) return undefined;
  return ({
    ...data,
    language: data.language || '',
    subject: data.subject || '',
    content: data.content || '',
    contentNumber: data.contentNumber || '',
  });
};

export const toTemplates = (data: unknown): Templates => {
  if (Array.isArray(data) && data.every((template) => isBackTemplate(template))) {
    const content = data.map((template) => toSelectableTemplate(template));
    const types: string[] = Array.from(new Set(data.map((template) => template.contenu_type)));
    return { types, content };
  }
  throw new Error('Templates Invalid Data');
};

export const toBackendTemplate = (data: EditableTemplate): TemplateBackend => ({
  name: data.name,
  contenu_type: data.type,
  contenu_numero: data.contentNumber,
  subject: data.subject,
  contenu: data.content,
  langue: data.language,
  is_default: data.isDefault,
});

export const toBackendCampaign = (
  projectId: string,
  {
    users, templates, segment, mailer,
  }: {
    users: number[],
    templates: number[],
    segment: string,
    mailer: string,
  },
): CampaignCreationBackend => ({
  project_id: projectId,
  user_id_list: users,
  template_id_list: templates,
  segment_list: [segment],
  mailer,
});
