import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'services/store/store';

import App from './App';

import './index.css';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>,
  );
}
