import React, { useState } from 'react';

import {
  Campaign, ProjectUser, CampaignEmail,
} from 'types/campaign';

import Modal from 'components/Modal';
import UsersInfo from 'components/UsersInfo';
import TableTitle from 'components/TableTitle';

import CampaignSummaryTableRows from './CampaignSummaryTableRows';
import CampaignSummaryTableEdition from './CampaignSummaryTableEdition';

interface CampaignSummaryTableProps {
  emails: CampaignEmail[],
  emailsSelected: number,
  users: ProjectUser[],
  disabled: boolean,
  onDelete: (id: number) => void,
  onSend: (id: number) => void,
  onEdit: (id: number, email: { subject: string, html: string }) => void,
  onSelect: (id: number) => void,
  onSelectAll: () => void,
  onUnselectAll: () => void,
}

export default function CampaignSummaryTable({
  emails, emailsSelected, users, disabled,
  onDelete, onEdit, onSend, onSelect, onSelectAll, onUnselectAll,
}: CampaignSummaryTableProps) {
  const [{ emailToUpdate, isPreview }, setEmailToUpdate] = useState<{
    emailToUpdate: Campaign | undefined,
    isPreview: boolean,
  }>({ emailToUpdate: undefined, isPreview: false });
  const [isUsersOpen, setIsUsersOpen] = useState(false);

  const openUsers = () => setIsUsersOpen((state) => !state);

  const handleEdit = (email: Campaign) => {
    setEmailToUpdate({ emailToUpdate: email, isPreview: false });
  };

  const handlePreview = (email: Campaign) => {
    setEmailToUpdate({ emailToUpdate: email, isPreview: true });
  };

  const handleClose = () => setEmailToUpdate({ emailToUpdate: undefined, isPreview: false });

  const handleEditSubmit = (id: number, email: { subject: string, html: string }) => {
    onEdit(id, email);
    handleClose();
  };

  const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onSelectAll();
    } else {
      onUnselectAll();
    }
  };

  return (
    <>
      {
        (emailToUpdate)
        && (
          <CampaignSummaryTableEdition
            email={emailToUpdate}
            onEdit={handleEditSubmit}
            onClose={handleClose}
            isPreview={isPreview}
          />
        )
      }
      {
        (isUsersOpen)
        && (
          <Modal onClose={openUsers}>
            <UsersInfo
              users={users}
              onClose={openUsers}
            />
          </Modal>
        )
      }
      <TableTitle
        onUserInfo={openUsers}
        onSelectAll={handleSelection}
        isSelectedAll={emailsSelected === emails.length}
      />
      <CampaignSummaryTableRows
        emails={emails}
        onDelete={onDelete}
        disabled={disabled}
        onEdit={handleEdit}
        onSend={onSend}
        onPreview={handlePreview}
        onSelect={onSelect}
      />
    </>
  );
}
