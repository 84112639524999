import React from 'react';

import styles from './DropdownItem.module.css';

interface DropdownItemProps {
  icon?: React.ReactNode,
  children: React.ReactNode,
  value: string,
  className?: string,
}

export default function DropdownItem({
  icon, children, value, className = '',
}: DropdownItemProps) {
  return (
    <button
      type="button"
      data-value={value}
      className={`${styles.item} ${className}`}
    >
      { icon }
      <span className={styles.label}>
        { children }
      </span>
    </button>
  );
}
