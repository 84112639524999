import React from 'react';

import styles from './CampaignSummaryCalendarTitle.module.css';

export default function CampaignSummaryCalendarTitle() {
  return (
    <>
      <p className={styles.title}>LUN</p>
      <p className={styles.title}>MAR</p>
      <p className={styles.title}>MER</p>
      <p className={styles.title}>JEU</p>
      <p className={styles.title}>VEN</p>
      <p className={styles.title}>SAM</p>
      <p className={styles.title}>DIM</p>
    </>
  );
}
