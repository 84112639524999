import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Error } from '@mui/icons-material';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import Button from 'components/library/Button';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';
import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import getErrorMessage from 'utils/error';
import useAPI from 'hooks/useAPI';
import layout from 'components/library/style/layout.module.css';
import { Status } from 'services/store/states';
import { toBackendTemplate, toEditableTemplate, toSelectableTemplate } from 'services/network/adaptor/campaign';
import { createTemplate, getTags, updateTemplateById } from 'services/network/api/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import { EditableTemplate, SelectableTemplate } from 'types/campaign';

import styles from './TemplateEdition.module.css';

interface TemplateEditionProps {
  onSubmit: (template: SelectableTemplate) => void,
  template?: SelectableTemplate,
}

export default function TemplateEdition({ template, onSubmit }: TemplateEditionProps) {
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [error, setError] = useState('');
  const [data, setData] = useState<EditableTemplate>(toEditableTemplate(template) || {
    name: '',
    type: 'actu',
    contentNumber: '1',
    subject: '',
    content: '',
    language: 'fr',
    isDefault: false,
  });

  const { status: tagStatus, data: tags } = useAPI({
    request: (source) => getTags(source),
  });

  const editorRef = useRef(null);

  const includeTextToCurrentPos = (event: any, text: string, ref: any) => {
    ref.current.model.change((writer: any) => writer
      .insertText(text, ref.current.model.document.selection.getFirstPosition()));
  };

  const dispatch = useDispatch();
  const contentRef = useRef(null);

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => setData((curr) => ({
    ...curr,
    [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value,
  }));
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => setData((curr) => ({
    ...curr,
    isDefault: e.target.checked,
  }));
  const handleTypeChange = (type: string) => setData((curr) => ({ ...curr, type }));
  const handleNumberChange = (contentNumber: string) => setData((curr) => ({
    ...curr,
    contentNumber,
  }));
  const handleLanguageChange = (language: string) => setData((curr) => ({ ...curr, language }));
  const handleSubmit = async () => {
    setStatus(Status.LOADING);
    try {
      const formattedData = toBackendTemplate({
        ...data,
        content: (contentRef.current && (contentRef.current as HTMLElement).innerHTML)
          || data.content,
      });
      const { data: newData } = (template?.id)
        ? await updateTemplateById(template.id.toString(), formattedData)
        : await createTemplate(formattedData);
      if (
        newData.status === true // Create template request send a status for error handling
        || newData.status === undefined // update template don't send status
      ) {
        setStatus(Status.SUCCESS);
        dispatch(addToast({
          type: 'success',
          title: 'Template sauvegardé !',
          description: 'Le template a été ajouté à votre liste',
        }) as any);
        onSubmit(toSelectableTemplate((newData.id) ? newData : formattedData));
      } else {
        setStatus(Status.FAIL);
        setError(newData.message);
      }
    } catch (err) {
      setStatus(Status.FAIL);
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de sauvegarder le template',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  const isSubmittable = () => (data.name.trim() !== '')
    && (['actu', 'sophie', 'marie'].includes(data.type))
    && (['1', '2', '3'].includes(data.contentNumber.toString()))
    && (data.subject.trim() !== '')
    && (data.content.trim() !== '')
    && (['en', 'fr'].includes(data.language));

  return (
    <div>
      <div className={layout.container}>
        <h2 className={styles.title}>
          {
            (!template?.id)
              ? 'Créer un nouveau template'
              : 'Modifier le template'
          }
        </h2>
        {
          (error)
          && (
            <p className={styles.error}>
              <Error />
              { error }
            </p>
          )
        }
        <Input label="Nom du template" name="name">
          <InputText
            placeholder="Nom du template"
            value={data.name}
            name="name"
            onChange={handleChange}
          />
        </Input>
        <Input label="Type de template" name="type">
          <Dropdown
            value={data.type}
            onChange={handleTypeChange}
            disabled={false}
          >
            <DropdownItem value="actu">Actu</DropdownItem>
            <DropdownItem value="sophie">Sophie</DropdownItem>
            <DropdownItem value="marie">Marie</DropdownItem>
          </Dropdown>
        </Input>
        <label
          htmlFor="isDefault"
          className={styles.default}
        >
          <input
            type="checkbox"
            name="isDefault"
            checked={data.isDefault}
            onChange={handleCheck}
          />
          Sélectionner le template par défault
        </label>
        <Input label="Numéro de contenu" name="contentNumber">
          <Dropdown
            value={data.contentNumber}
            onChange={handleNumberChange}
            disabled={false}
          >
            <DropdownItem value="1">1</DropdownItem>
            <DropdownItem value="2">2</DropdownItem>
            <DropdownItem value="3">3</DropdownItem>
          </Dropdown>
        </Input>
        <Input label="Sujet" name="subject">
          <InputText
            placeholder="Sujet de l'email"
            name="subject"
            value={data.subject}
            onChange={handleChange}
          />
        </Input>
        <div>
          <p>Tags</p>
          {
            (tagStatus === Status.SUCCESS)
              ? (
                <div className={styles['tag-list']}>
                  {
                    (tags as string[]).map((tag: string) => (
                      <button
                        key={tag}
                        type="button"
                        className={styles.tag}
                        onClick={(e) => includeTextToCurrentPos(e, `[%${tag}%] `, editorRef)}
                      >
                        {tag}
                      </button>
                    ))
                  }
                </div>
              )
              : <p>Chargement...</p>
          }
        </div>
        <Input label="Content" name="content">
          <CKEditor
            editor={ClassicEditor}
            data={data.content}
            onChange={(event: any, editor: any) => {
              if (editor.getData() !== '') {
                setData((curr) => ({
                  ...curr,
                  content: (editor.getData()),
                }));
              } else {
                setData((curr) => ({
                  ...curr,
                  content: '',
                }));
              }
            }}
            onReady={(editor: any) => {
              editorRef.current = editor;
            }}
          />
        </Input>
        <Input label="Langue" name="language">
          <Dropdown
            value={data.language}
            onChange={handleLanguageChange}
            disabled={false}
          >
            <DropdownItem value="fr">fr</DropdownItem>
            <DropdownItem value="en">en</DropdownItem>
          </Dropdown>
        </Input>
        <Button
          onClick={handleSubmit}
          disabled={!isSubmittable() || status === Status.LOADING}
        >
          Sauvegarder le template
        </Button>
      </div>
    </div>
  );
}
