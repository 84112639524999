import React from 'react';

import styles from './InputText.module.css';

export default function InputText({
  className, name, value, onChange, onKeyDown, placeholder, children,
}: {
  className?: string, name?: string,
  placeholder: string, value: string, onChange: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  children?: React.ReactNode,
}) {
  return (
    <div className={`${className} ${styles.container}`}>
      { children }
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={styles.input}
      />
    </div>
  );
}
