import { Dispatch } from 'react';
import {
  createCampaigns, sendCampaignById, updateAllCampaignsByProjectId,
} from 'services/network/api/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import { CampaignEmail } from 'types/campaign';
import {
  toBackendCampaign,
} from 'services/network/adaptor/campaign';
import getErrorMessage from './error';

export const sendCampaign = async (
  id: number,
  dispatch: Dispatch<any>,
  projectId?: number,
  callback?: () => void,
) => {
  if (projectId) {
    try {
      await sendCampaignById(id, projectId);
      if (callback) callback();
      dispatch(addToast({
        type: 'success',
        title: 'La tâche a été créée avec succès',
        description: '',
      }));
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible d\'envoyer la campagne',
        description: getErrorMessage(err),
      }));
    }
  }
};

export const updateAllCampaigns = async (
  dispatch: Dispatch<any>,
  projectId?: number,
) => {
  if (projectId) {
    try {
      const { data } = await updateAllCampaignsByProjectId(projectId);
      dispatch(addToast({
        type: 'success',
        title: 'Les campagnes ont bien été mises à jour',
        description: data.message,
      }));
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de mettre à jour les campagnes',
        description: getErrorMessage(err),
      }));
    }
  }
};

export const getEmailsFromQuery = (query: string, emails: CampaignEmail[]) => {
  if (!query || query === '') return emails;
  return ((emails as CampaignEmail[]).filter(
    (email: CampaignEmail) => ((`${email.name} ${email.subject} ${email.template} ${email.sendgrid} ${email.segment}`.toLowerCase())
      .includes(query.toLowerCase())),
  ));
};

export const createCampaign = async (
  email: {
    users: number[],
    templates: number[],
    segment: string,
    mailer: string,
  },
  dispatch: Dispatch<any>,
  projectId?: number,
  callback?: () => void,
) => {
  if (projectId) {
    try {
      await createCampaigns(
        toBackendCampaign(
          projectId.toString(),
          email,
        ),
      );
      dispatch(addToast({
        type: 'success',
        title: 'Les campagnes sont en cours de création.',
        description: 'La création de campagne peut prendre une dizaine de minutes.',
      }));
      if (callback) callback();
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Impossible de créer les campagnes',
        description: getErrorMessage(err),
      }));
    }
  }
};
