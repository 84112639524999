import React, { useRef, useEffect } from 'react';

import useOutsideClickAlert from 'hooks/useOutsideClick';

import styles from './Modal.module.css';

interface ModalProps {
  children: React.ReactNode,
  onClose: () => void,
}

export default function Modal({ children, onClose }: ModalProps) {
  const modal = useRef(null);
  useOutsideClickAlert(modal, onClose);

  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
    return () => {
      window.document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.background}>
      <section
        className={styles.modal}
        ref={modal}
      >
        { children }
      </section>
    </div>
  );
}
