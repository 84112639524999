import React, { useState } from 'react';
import { ArrowBack, ExpandLess, ExpandMore } from '@mui/icons-material';

import useAPI from 'hooks/useAPI';
import { getTemplates } from 'services/network/api/campaign';
import { toTemplates } from 'services/network/adaptor/campaign';

import SegmentsInfo from 'components/SegmentsInfo';
import Button from 'components/library/Button';
import Loading from 'components/library/Loading';
import Input from 'components/library/Input';
import layout from 'components/library/style/layout.module.css';
import LoadingSpinner from 'components/library/LoadingSpinner';

import {
  Project, ProjectUser, SelectableTemplate,
} from 'types/campaign';

import CampaignCreationTemplate from './CampaignCreationTemplate';
import styles from './CampaignCreation.module.css';

interface CampaignCreationProps {
  project: Project,
  users: ProjectUser[],
  onPrevious: () => void,
  onSubmit: (users: number[], templates: number[], segment: string, mailer: string) => void,
  isLoading?: boolean,
}

export default function CampaignCreation({
  project, users, onPrevious, onSubmit, isLoading = false,
}: CampaignCreationProps) {
  const [usersWithSelect, setUsersWithSelect] = useState(users
    .map((user) => ({ ...user, isSelected: false })));
  const [isSegmentsOpen, setIsSegmentsOpen] = useState(false);
  const [segment, setSegment] = useState('all');
  const [mailer, setMailer] = useState('sendgrid');

  const { status, data, setData } = useAPI({
    request: (source) => getTemplates(source),
    adaptor: toTemplates,
  });

  const handleSegments = () => setIsSegmentsOpen((state) => !state);

  const handleUserSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target as HTMLInputElement;
    setUsersWithSelect((state) => state.map((user) => (
      (user.id.toString() === name)
        ? ({ ...user, isSelected: checked })
        : user)));
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target as HTMLInputElement;
    setUsersWithSelect((state) => state.map((user) => {
      if (!user.isActive) return user;
      return ({
        ...user,
        isSelected: checked,
      });
    }));
  };

  const getIsAllSelected = () => {
    const activeUsers = usersWithSelect.filter((user) => user.isActive);
    return activeUsers.filter((user) => user.isSelected).length === activeUsers.length;
  };

  const handleTemplateSelection = (name: string, isSelected: boolean) => {
    setData((state) => (state ? ({
      ...state,
      content: state.content.map((template: SelectableTemplate) => (
        (template.name === name)
          ? ({ ...template, isSelected })
          : template
      )),
    }) : undefined));
  };

  const handleSegment = (e: React.ChangeEvent) => setSegment((e.target as HTMLSelectElement).value);

  const handleMailer = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMailer(e.target.value);
  };

  const handleSubmit = () => onSubmit(
    usersWithSelect
      .filter((user) => user.isSelected)
      .map((user) => user.id),
    data?.content
      .filter((template: SelectableTemplate) => template.isSelected && (template.id !== undefined))
      .map((template: SelectableTemplate) => template.id as number) || [],
    segment,
    mailer,
  );

  const getIsSubmitDisable = () => !((usersWithSelect.filter((user) => user.isSelected).length > 0)
    && ((data?.content || [])
      .filter((template: SelectableTemplate) => template.isSelected).length > 0));

  return (
    <section>
      {
        (isLoading)
        && (
          <div className={styles.background}>
            <section className={styles.modal}>
              <LoadingSpinner />
              <p className={styles.hint}>
                Création de la campagne en cours...
              </p>
            </section>
          </div>
        )
      }
      <header className={styles.header}>
        <div className={layout.container}>
          <Button
            variant="ghost"
            className={styles.back}
            onClick={onPrevious}
          >
            <ArrowBack className={styles.icon} />
            Retour
          </Button>
          <h1 className={styles.title}>
            {`Création de campagne de #${project.id} : ${project.name}`}
          </h1>
        </div>
      </header>
      <main className={styles.main}>
        <div className={layout.container}>
          <div className={styles.form}>
            <label
              htmlFor="all"
              className={styles.users}
            >
              <input
                type="checkbox"
                name="all"
                id="all"
                onChange={handleSelectAll}
                checked={getIsAllSelected()}
              />
              Users
            </label>
            <div className={styles.list}>
              {
                usersWithSelect.map((user) => (
                  <label
                    htmlFor={user.id.toString()}
                    key={user.id}
                    className={user.isActive ? '' : styles.inactive}
                  >
                    <input
                      name={user.id.toString()}
                      type="checkbox"
                      id={user.id.toString()}
                      className={styles.checkbox}
                      checked={user.isSelected}
                      onChange={handleUserSelection}
                      disabled={!user.isActive}
                    />
                    <span
                      className={styles.active}
                    >
                      {`${user.role} - ${user.username}`}
                    </span>
                  </label>
                ))
              }
            </div>
            <div>
              <p className={styles.template}>Templates</p>
              <Loading status={status}>
                <div className={styles['template-group']}>
                  {
                    data?.types.map((type: string) => (
                      <CampaignCreationTemplate
                        key={type}
                        label={type}
                        templates={data?.content
                          .filter((template: SelectableTemplate) => template.type === type)}
                        onChange={handleTemplateSelection}
                      />

                    ))
                  }
                </div>
              </Loading>
            </div>
            <div>
              <p className={styles.segments}>
                Segments
              </p>
              <label
                htmlFor="segments"
                className={styles['select-container']}
              >
                Sélectionner le segment souhaité
                <select
                  name="segments"
                  className={styles.input}
                  onChange={handleSegment}
                >
                  <option value="ALL">All</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="Nouveaux profils">Nouveaux profils</option>
                </select>
              </label>
              <Button
                variant="ghost"
                onClick={handleSegments}
              >
                Voir les segments
                {
                  (isSegmentsOpen)
                    ? <ExpandLess />
                    : <ExpandMore />
                }
              </Button>
              {
                (isSegmentsOpen)
                && (
                  <SegmentsInfo
                    hideHeader
                  />
                )
              }
            </div>
            <p className={styles.segments}>
              Mailer
            </p>
            <Input
              label="Mailer"
              name="mailer"
            >
              <select
                value={mailer}
                onChange={handleMailer}
                className={styles.input}
              >
                <option value="sendgrid">sendgrid</option>
              </select>
            </Input>
          </div>
          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={getIsSubmitDisable() || isLoading}
          >
            Valider cette configuration pour créer la campagne
          </Button>
        </div>
      </main>
    </section>
  );
}
