/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route, useNavigate,
  Outlet, Navigate,
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import type { RootState } from 'services/store/store';
import verifyToken from 'services/store/features/roles/actions';

import LoadingSpinner from 'components/library/LoadingSpinner';
import Navigation from 'components/Navigation';

import ConfigurationCampaign from 'views/Configuration/Campaign';
import ConfigurationSearch from 'views/Configuration/Search';
import Tasks from 'views/Tasks';
import WaitingCampaign from 'views/WaitingCampaign';
import Template from 'views/Template';

type RouteProps = {
  path: string,
  auth: boolean,
  status?: string,
  roles: string[],
  authorizedRole: string[],
  routes: any,
  component: any,
};

const ROUTES:RouteProps[] = [
  {
    path: '/configuration/:id',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <ConfigurationCampaign />,
  },
  {
    path: '/template',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <Template />,
  },
  {
    path: '/configuration',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <ConfigurationSearch />,
  },
  {
    path: '/waiting',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <WaitingCampaign />,
  },
  {
    path: '/tasks',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <Tasks />,
  },
  {
    path: '/',
    auth: true,
    roles: [],
    authorizedRole: [],
    routes: [],
    component: () => <Navigate to="/configuration" />,
  },
];

function ProtectedRoute({
  status, roles, authorizedRole,
}: ({
  status: string, roles: string[], authorizedRole: string[]
})) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyToken() as any);
  }, []);

  useEffect(() => {
  }, [authorizedRole]);

  useEffect(() => {
    if (roles && roles.length > 0 && status === 'SUCCEEDED') {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (window.history.length === 1) {
            document.location.assign(process.env.REACT_APP_DEBUG ? 'https//dev-auth.ayomi.fr/' : 'https//auth.ayomi.fr/');
          } else {
            navigate(-1);
          }
        }
      }
    }
  }, [status]);

  if (status === 'SUCCEEDED') {
    return (<Outlet />);
  }

  return <LoadingSpinner />;
}

const mapStateToProps = (state: RootState) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedProtectedRoute = connect(mapStateToProps)(ProtectedRoute);

export default function RouteConfig() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route element={<ConnectedProtectedRoute roles={[]} />}>
          {ROUTES.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      </Routes>
    </Router>
  );
}
