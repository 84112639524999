import React, { useRef, useState } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import useOutsideClickAlert from 'hooks/useOutsideClick';
import styles from './Dropdown.module.css';

type DropdownType = {
  value?: string,
  className?: string,
  onChange: (value: string, callback?: () => void) => void,
  icon?: React.ReactNode,
  disabled: boolean,
  isDropdownIconDisabled?: boolean,
  shouldCloseAfterClick?: boolean,
  children: React.ReactNode,
};

export default function Dropdown({
  value, disabled, className, icon, isDropdownIconDisabled = false, shouldCloseAfterClick = true,
  onChange, children,
}: DropdownType) {
  const [isOpen, setIsOpen] = useState(false);

  const modal = useRef(null);

  useOutsideClickAlert(modal, () => isOpen && setIsOpen(false));

  const handleClick = () => setIsOpen((state) => !state);

  const handleDropdownItemClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    const currentValue = (e.target as HTMLElement).dataset.value;
    const close = () => setIsOpen(false);
    if (currentValue) {
      onChange(currentValue, close);
      if (shouldCloseAfterClick) close();
      return;
    }
    const parentValue = ((e.target as HTMLElement).parentElement as HTMLElement).dataset.value;
    if (parentValue) {
      onChange(parentValue, close);
      if (shouldCloseAfterClick) close();
    }
  };

  return (
    <div
      ref={modal}
      className={styles.dropdown}
    >
      <button
        type="button"
        onClick={handleClick}
        className={`${className || styles.custom} ${!isDropdownIconDisabled ? styles.button : ''} ${disabled ? styles.disabled : ''}`}
        disabled={disabled}
      >
        { value }
        <div className={styles.center}>
          {
            (!isDropdownIconDisabled)
            && (
              (isOpen)
                ? <ExpandLess className={styles.icon} />
                : <ExpandMore className={styles.icon} />
            )
          }
          { icon }
        </div>
      </button>
      {
        (isOpen)
        && (
          <div
            className={styles.modal}
            tabIndex={-1}
            role="button"
            onClick={handleDropdownItemClick}
            onKeyDown={handleDropdownItemClick}
          >
            { children }
          </div>
        )
      }
    </div>
  );
}
