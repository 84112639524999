import { useState } from 'react';
import * as dateFns from 'date-fns';
import { getMonth } from 'date-fns';

const monthList = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

const useCalendar = () => {
  const today = new Date();
  const [type, setType] = useState('month');
  const [currentDate, setCurrentDate] = useState(today);
  const getDaysOfWeek = (currentDay: Date) => {
    let id = 0;
    const start = dateFns.startOfWeek(currentDay, { weekStartsOn: 1 });
    const end = dateFns.endOfWeek(currentDay, { weekStartsOn: 1 });
    const week = [];
    let day = start;
    let formattedDate = '';
    while (day <= end) {
      formattedDate = dateFns.format(day, 'd');
      week.push({
        id,
        timestamp: day,
        date: formattedDate,
        day: day.toLocaleDateString('fr-FR', { weekday: 'short' }),
        month: day.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }),
        selected: dateFns.isSameDay(day, currentDay),
        sameMonth: getMonth(currentDay) === getMonth(day),
        isPast: dateFns.isBefore(day, new Date()),
      });
      day = dateFns.addDays(day, 1);
      id += 1;
    }
    return week;
  };
  const getDaysOfMonth = (currentDay: Date) => {
    let id = 0;
    let start = dateFns.startOfMonth(currentDay);
    start = dateFns.startOfWeek(start, { weekStartsOn: 1 });
    const end = dateFns.endOfMonth(currentDay);
    const month = [];
    let day = start;
    let formattedDate = '';
    while (day <= dateFns.addDays(end, 7 - end.getDay())) {
      formattedDate = dateFns.format(day, 'd');
      month.push({
        id,
        timestamp: day,
        date: formattedDate,
        day: day.toLocaleDateString('fr-FR', { weekday: 'short' }),
        month: day.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }),
        selected: dateFns.isSameDay(day, today),
        sameMonth: getMonth(currentDay) === getMonth(day),
        isPast: dateFns.isBefore(day, new Date()),
      });
      day = dateFns.addDays(day, 1);
      id += 1;
    }
    return month;
  };
  const [days, setDays] = useState(getDaysOfMonth(today));

  const previous = () => {
    if (type === 'week') {
      const newWeek = dateFns.subWeeks(currentDate, 1);
      setCurrentDate(newWeek);
      setDays(getDaysOfWeek(newWeek));
    } else if (type === 'month') {
      const newMonth = dateFns.subMonths(currentDate, 1);
      setCurrentDate(newMonth);
      setDays(getDaysOfMonth(newMonth));
    }
  };

  const next = () => {
    if (type === 'week') {
      const newWeek = dateFns.addWeeks(currentDate, 1);
      setCurrentDate(newWeek);
      setDays(getDaysOfWeek(newWeek));
    } else if (type === 'month') {
      const newMonth = dateFns.addMonths(currentDate, 1);
      setCurrentDate(newMonth);
      setDays(getDaysOfMonth(newMonth));
    }
  };

  const getPreviousDay = () => {
    const newDay = dateFns.subDays(currentDate, 1);
    setCurrentDate(newDay);
    setDays(getDaysOfWeek(newDay));
  };

  const getNthPreviousDay = (nbDays: number) => dateFns.subDays(currentDate, nbDays);

  const getNextDay = () => {
    const newDay = dateFns.addDays(currentDate, 1);
    setCurrentDate(newDay);
    setDays(getDaysOfWeek(newDay));
  };

  const handleTypeChange = (typeInput: 'week' | 'month') => {
    setType(typeInput);
    if (typeInput === 'week') {
      setDays(getDaysOfWeek(today));
    } else if (typeInput === 'month') {
      setDays(getDaysOfMonth(today));
    }
  };

  const getToday = () => {
    if (type === 'week') {
      setCurrentDate(today);
      setDays(getDaysOfWeek(today));
    } else if (type === 'month') {
      setCurrentDate(today);
      setDays(getDaysOfMonth(today));
    }
  };

  const getFormattedDay = (d: { date: number, month: string }) => {
    const monthYear = d.month.split(' ');
    return (`${monthYear[1]}-${monthList.indexOf(monthYear[0]) + 1 <= 9 ? 0 : ''}${monthList.indexOf(monthYear[0]) + 1}-${d.date <= 9 ? 0 : ''}${d.date}`);
  };

  return {
    today,
    currentDate,
    days,
    previous,
    next,
    type,
    handleTypeChange,
    getToday,
    getFormattedDay,
    getPreviousDay,
    getNthPreviousDay,
    getNextDay,
    getDaysOfWeek,
    getDaysOfMonth,
  };
};

export default useCalendar;
