import { combineReducers } from 'redux';
import rolesReducer from './features/roles/reducer';
import toastsReducer from './features/toasts/reducer';

const rootReducer = combineReducers({
  roles: rolesReducer,
  toasts: toastsReducer,
});

export default rootReducer;
