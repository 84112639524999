import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';

import Modal from 'components/Modal';
import Button from 'components/library/Button';
import { ProjectUser } from 'types/campaign';
import CampaignConfigurationSenderEdit from './CampaignConfigurationSenderEdit';

import styles from './CampaignConfigurationSender.module.css';

interface Props {
  senders?: ProjectUser[],
}

export default function CampaignConfigurationSender({ senders }: Props) {
  const [data, setData] = useState(senders || []);
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <Button
        variant="ghost"
        onClick={() => setIsOpen(true)}
      >
        <Edit className={styles.icon} />
        Listes des senders
      </Button>
    );
  }

  return (
    <Modal onClose={() => setIsOpen(false)}>
      <div className={styles.container}>
        <p className={styles.title}>Sender</p>
        {
          (data)
            ? (
              <ul className={styles.list}>
                {
                  data
                    .filter((user) => user.isActive)
                    .map((sender) => (
                      <CampaignConfigurationSenderEdit
                        key={sender.email}
                        sender={sender}
                        setData={setData}
                      />
                    ))
                }
              </ul>
            )
            : (<p> Aucun sender trouvé</p>)
        }
        <Button
          variant="outline"
          onClick={() => setIsOpen(false)}
        >
          Fermer
        </Button>
      </div>
    </Modal>
  );
}
