import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from '@mui/icons-material';

import layout from 'components/library/style/layout.module.css';
import Button from 'components/library/Button';

import styles from './Search.module.css';

export default function SearchCampaign() {
  const [id, setId] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    const lastCampaignVisited = window.localStorage.getItem('campaign');
    if (lastCampaignVisited) {
      navigate(`/configuration/${lastCampaignVisited}`, { replace: true });
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value);
  const handleAccess = () => navigate(`/configuration/${id}`, { replace: true });
  const handleSubmitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (id && e.key === 'Enter') {
      handleAccess();
    }
  };

  return (
    <section className={`fadeIn ${layout.container} ${layout.section} ${styles.search}`}>
      <div className={styles.logo}>
        <p className={styles.ayomi}>
          Ayomi
        </p>
        <div className={styles.point} />
        <p className={styles.campaign}>
          Campaign
        </p>
      </div>
      <div className={styles.searchbar}>
        <Search className={styles.icon} />
        <input
          type="number"
          placeholder="Taper un ID projet pour commencer"
          value={id || ''}
          onChange={handleChange}
          onKeyDown={handleSubmitOnEnter}
          className={styles.input}
        />
      </div>
      <Button
        onClick={handleAccess}
      >
        Accéder à la configuration de campagne
      </Button>
    </section>
  );
}
