import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { sendTestCampaignById } from 'services/network/api/campaign';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';

import Modal from 'components/Modal';
import InputText from 'components/library/InputText';
import Input from 'components/library/Input';
import Button from 'components/library/Button';

import { CampaignEmail } from 'types/campaign';

import styles from './TestEmailsModal.module.css';

interface TestEmailsModalProps {
  selectedEmails: CampaignEmail[],
  onClose: () => void,
}

export default function TestEmailsModal({ selectedEmails, onClose }: TestEmailsModalProps) {
  const [emails, setEmails] = useState('');
  const dispatch = useDispatch();

  const handleEmails = (e: React.ChangeEvent<HTMLInputElement>) => setEmails(e.target.value);
  const handleSubmit = async () => {
    try {
      const testEmails = selectedEmails.map((email) => sendTestCampaignById(email.id, emails));
      await Promise.all(testEmails);
      dispatch(addToast({
        type: 'success',
        title: 'Envois des campagnes test réussis',
        description: '',
      }) as any);
      onClose();
    } catch (err) {
      dispatch(addToast({
        type: 'error',
        title: 'Échec des envoies test des campagnes',
        description: getErrorMessage(err),
      }) as any);
    }
  };

  return (
    <Modal onClose={onClose}>
      <header className={styles.header}>
        <p>
          Envoi de campagne test
        </p>
      </header>
      <main className={styles.main}>
        <Input
          label="Email test"
          name="emailsTest"
        >
          <InputText
            placeholder="email@domaine.fr"
            value={emails}
            onChange={handleEmails}
          />
        </Input>
      </main>
      <footer className={styles.footer}>
        <Button
          variant="ghost"
          onClick={onClose}
        >
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!emails || emails === ''}
        >
          Envoyer
        </Button>
      </footer>
    </Modal>
  );
}
