import React from 'react';
import { Cancel, CheckCircle } from '@mui/icons-material';

import Button from 'components/library/Button';
import { ProjectUser } from 'types/campaign';

import styles from './UsersInfo.module.css';
import CopyText from '../CopyText';

interface UsersInfoProps {
  users: ProjectUser[],
  onClose: () => void,
}

export default function UsersInfo({ users, onClose }: UsersInfoProps) {
  return (
    <>
      <header className={styles.header}>
        <p className={styles.title}>
          Informations sur les utilisateurs
        </p>
      </header>
      <main className={styles.main}>
        <div className={styles.grid}>
          <p className={styles.bold}>Nom</p>
          <p className={styles.bold}>Role</p>
          <p className={styles.bold}>Email</p>
          <p className={styles.bold}>Actif</p>
          {
            users.map((user: ProjectUser) => (
              <>
                <p
                  key={`${user.id}-${user.username}`}
                  className={styles.bold}
                >
                  {user.username}
                </p>
                <p key={`${user.id}-${user.role}`}>{user.role}</p>
                <CopyText label={user.email} />
                <p key={`${user.id}-${user.isActive}`}>
                  {
                    user.isActive
                      ? <CheckCircle className={styles.success} />
                      : <Cancel className={styles.error} />
                  }
                </p>
              </>
            ))
          }
        </div>
      </main>
      <footer className={styles.footer}>
        <Button
          type="custom"
          onClick={onClose}
          className={styles.close}
        >
          Fermer
        </Button>
      </footer>
    </>
  );
}
