import React, { useRef } from 'react';

import styles from './CampaignCreationTemplate.module.css';

interface CampaignCreationTemplateProps {
  label: string,
  templates: { name: string, isSelected: boolean }[],
  onChange: (name: string, isSelected: boolean) => void,
}

export default function CampaignCreationTemplate({
  label, templates, onChange,
}: CampaignCreationTemplateProps) {
  const modal = useRef(null);

  const handleChange = (e: React.ChangeEvent) => {
    const { name, checked } = (e.target as HTMLInputElement);
    onChange(name, checked);
  };

  const selectAll = (e: React.ChangeEvent) => {
    const { checked } = (e.target as HTMLInputElement);
    templates.forEach((template) => onChange(template.name, checked));
  };

  return (
    <div
      className={styles['template-container']}
      ref={modal}
    >
      <label className={styles.label} htmlFor={`select-all-${label}`}>
        <input
          type="checkbox"
          id={`select-all-${label}`}
          checked={templates.every((template) => template.isSelected)}
          onChange={selectAll}
          className={styles['select-all']}
        />
        { label }
      </label>
      <hr
        className={styles.divider}
      />
      <div className={styles.template}>
        {
          templates
            .map((template) => (
              <label
                key={template.name}
                htmlFor={template.name}
                className={styles.checkbox}
              >
                <input
                  type="checkbox"
                  name={template.name}
                  id={template.name}
                  checked={template.isSelected || false}
                  onChange={handleChange}
                />
                { template.name }
              </label>
            ))
        }
      </div>
    </div>
  );
}
