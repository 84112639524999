import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'components/library/Loading';
import LoadingSpinner from 'components/library/LoadingSpinner';
import useAPI from 'hooks/useAPI';
import {
  toCampaigns, toProject,
} from 'services/network/adaptor/campaign';
import {
  getCampaignsByProjectId,
  getProjectData, getProjectPageData, getSenders, getTasks,
} from 'services/network/api/campaign';
import { Status } from 'services/store/states';
import { Task } from 'types/campaign';
import { keysToCamel } from 'utils/converter';

import CampaignSummary from './CampaignSummary';

export default function Campaign() {
  const { id } = useParams();
  const {
    status, data, fetchData, setData,
  } = useAPI({
    multiRequest: (source) => [
      getCampaignsByProjectId(id || '0', source),
      getProjectData(id || '0', source),
      getProjectPageData(id || '0', source),
      getTasks(source),
      getSenders(id || '0', source),
    ],
    adaptor: (response: any) => ({
      campaign: toCampaigns(response[0]),
      project: toProject({ ...response[1], ...response[2], senders: response[4] }),
      task: response[3].find((task: Task) => (task.status === 'waiting' || task.status === 'running')),
    }),
  });

  useEffect(() => {
    if (id) window.localStorage.setItem('campaign', id);

    if (status === Status.SUCCESS) {
      fetchData({});
    }
  }, [id]);

  useEffect(() => {
    const fetchTask = async () => {
      if (data?.task) {
        let { data: taskData } = await getTasks();
        taskData = keysToCamel(taskData);
        if (taskData && !taskData.find((task: Task) => (task?.projectId?.toString() === id)
          && (task.status === 'waiting' || task.status === 'running'))) {
          setData((content) => {
            if (!content) return undefined;
            return {
              ...content,
              task: undefined,
            };
          });
        }
      }
    };
    if (data?.task && status === Status.SUCCESS) {
      fetchTask();
    }
  }, [data?.task]);

  return (
    <section>
      <Loading status={status}>
        {
          (data)
            ? (
              <CampaignSummary
                campaigns={data}
                fetchCampaigns={() => fetchData({})}
                setCampaigns={setData as any}
              />
            )
            : <LoadingSpinner />
        }
      </Loading>
    </section>
  );
}
