import React, { useState } from 'react';
import {
  AccessTime, Event,
  CalendarViewMonth, Error, Search, Send, TableView, Update, Check, Close,
} from '@mui/icons-material';

import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import Dropdown from 'components/library/Dropdown';
import DropdownItem from 'components/library/DropdownItem';

import layout from 'components/library/style/layout.module.css';

import styles from './CampaignSummaryToolbar.module.css';

interface CampaignSummaryToolbarProps {
  disabled: boolean,
  openCreation: () => void,
  onAction: (action: string) => void;
  children: (isTable: boolean, query: string) => React.ReactNode,
}

export default function CampaignSummaryToolbar({
  disabled, openCreation,
  onAction, children,
}: CampaignSummaryToolbarProps) {
  const [query, setQuery] = useState('');
  const [isTableView, setIsTableView] = useState(true);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setIsTableView(true);
  };
  const handleViewChange = (e: React.MouseEvent<HTMLButtonElement>) => setIsTableView((e.target as HTMLElement).dataset.id === 'table');
  const handleOption = (value: string) => onAction(value);

  return (
    <>
      <div className={styles.toolbar}>
        <div className={`${layout.container} ${styles.row}`}>
          <div className={styles['input-tool']}>
            <InputText
              placeholder="Recherche"
              value={query}
              onChange={handleSearch}
              className={styles.input}
            >
              <Search className={styles.icon} />
            </InputText>
          </div>
          <div className={styles.buttongroup}>
            <Button
              id="table"
              type="custom"
              variant="ghost"
              onClick={handleViewChange}
              className={!isTableView ? styles.inactive : styles.active}
            >
              <TableView className={styles.buttonicon} />
              Table view
            </Button>
            <Button
              id="calendar"
              type="custom"
              variant="ghost"
              onClick={handleViewChange}
              className={isTableView ? styles.inactive : styles.active}
            >
              <CalendarViewMonth className={styles.buttonicon} />
              Calendar view
            </Button>
            <Button
              onClick={openCreation}
              disabled={disabled}
            >
              + Campaign
            </Button>
            <Dropdown
              className={styles.expand}
              onChange={handleOption}
              disabled={disabled}
            >
              <>
                <DropdownItem
                  className={styles.item}
                  value="auto-schedule"
                >
                  <Event />
                  Auto-schedule des campagnes
                </DropdownItem>
                <DropdownItem
                  className={styles.item}
                  value="update-all"
                >
                  <Update />
                  MAJ toutes les campagnes
                </DropdownItem>
                <DropdownItem
                  className={styles.item}
                  value="email"
                >
                  <Send />
                  Envoyer des emails de test
                </DropdownItem>
                <DropdownItem
                  className={styles.item}
                  value="validate"
                >
                  <Check />
                  Valider campagnes sélectionnées
                </DropdownItem>
                <DropdownItem
                  className={styles.item}
                  value="schedule"
                >
                  <AccessTime />
                  Schedule campagnes sélectionnées
                </DropdownItem>
                <DropdownItem
                  className={`${styles.item} ${styles.error}`}
                  value="unscheduled"
                >
                  <AccessTime />
                  Unschedule campagnes sélectionnées
                </DropdownItem>
                <DropdownItem
                  className={`${styles.item} ${styles.error}`}
                  value="invalidate"
                >
                  <Close />
                  Invalider campagnes sélectionnées
                </DropdownItem>
                <DropdownItem
                  className={`${styles.item} ${styles.error}`}
                  value="delete"
                >
                  <Error />
                  Supprimer campagnes sélectionnées
                </DropdownItem>
              </>
            </Dropdown>
          </div>
        </div>
      </div>
      { children(isTableView, query) }
    </>
  );
}
